/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

export const COLUMN_NAMES = {
  byName: {
    __counter_key: '__counter_key',
    __counter_key_1: '__counter_key_1',
    _airwatch_device__device_friendly_name: '_airwatch_device__device_friendly_name',
    _airwatch_device__device_location_group_lineage_list: '_airwatch_device__device_location_group_lineage_list',
    _airwatch_device__device_location_group_name: '_airwatch_device__device_location_group_name',
    _airwatch_device_guid: '_airwatch_device_guid',
    _airwatch_device_guids: '_airwatch_device_guids',
    _alert_category: '_alert_category',
    _app_crash_rate_score: '_app_crash_rate_score',
    _app_hang_rate_score: '_app_hang_rate_score',
    _app_long_version: '_app_long_version',
    _app_version: '_app_version',
    _app_short_version: '_app_short_version',
    _application_name: '_application_name',
    _application_names: '_application_names',
    _average_boot_time_millis_score: '_average_boot_time_millis_score',
    _average_shutdown_time_millis_score: '_average_shutdown_time_millis_score',
    _battery_health_score: '_battery_health_score',
    _consumer_org_id: '_consumer_org_id',
    _cpu_health_score: '_cpu_health_score',
    _desktop_app_overall_score: '_desktop_app_overall_score',
    _device_crash_rate_score: '_device_crash_rate_score',
    _device_friendly_name: '_device_friendly_name',
    _device_model: '_device_model',
    _device_name: '_device_name',
    _device_os_version: '_device_os_version',
    _device_overall_score: '_device_overall_score',
    _device_platform: '_device_platform',
    _device_guid: '_device_guid',
    _error_name: '_error_name',
    _event_id: '_event_id',
    _event_type: '_event_type',
    _event_received_timestamp: '_event_received_timestamp',
    _gpu_health_score: '_gpu_health_score',
    _hash_pfn: '_hash_pfn',
    _integration: '_integration',
    _http_status_message: '_http_status_message',
    _latency: '_latency',
    _memory_health_score: '_memory_health_score',
    _merged_series: '_merged_series',
    _mobile_app_avg_app_latency_millis_score: '_mobile_app_avg_app_latency_millis_score',
    _mobile_app_crash_rate_score: '_mobile_app_crash_rate_score',
    _mobile_app_handled_exceptions_score: '_mobile_app_handled_exceptions_score',
    _mobile_app_net_error_rate_score: '_mobile_app_net_error_rate_score',
    _mobile_app_overall_score: '_mobile_app_overall_score',
    _net_error_msg: '_net_error_msg',
    _org_id: '_org_id',
    _org_overall_score: '_org_overall_score',
    _state_name: '_state_name',
    _threat_action: '_threat_action',
    _threat_family: '_threat_family',
    _threat_id: '_threat_id',
    _threat_severity: '_threat_severity',
    _timestamp: '_timestamp',
    _user_guid: '_user_guid',
    _user_name: '_user_name',
    _user_overall_score: '_user_overall_score',
    _url: '_url',
    _url_domain: '_url_domain',
    _url_host: '_url_host',
    _url_scheme: '_url_scheme',
    _vmware_threat_type: '_vmware_threat_type',
    account_access_level: 'account_access_level',
    account_summary: 'account_summary',
    action: 'action',
    actions: 'actions',
    action_id: 'action_id',
    action_name: 'action_name',
    action_title: 'action_title',
    active: 'active',
    active_time_millis: 'active_time_millis',
    actor_id: 'actor_id',
    actor_display_name: 'actor_display_name',
    actordomain: 'actordomain',
    actorusername: 'actorusername',
    address: 'address',
    adp_modified_at: 'adp_modified_at',
    app: 'app',
    app_average_cpu_usage: 'app_average_cpu_usage',
    app_average_memory_usage: 'app_average_memory_usage',
    app_bundle_id: 'app_bundle_id',
    app_bundle_size_bytes: 'app_bundle_size_bytes',
    app_config_app_name: 'app_config_app_name',
    app_cpu_usage_percentage: 'app_cpu_usage_percentage',
    app_crash_count: 'app_crash_count',
    app_crash_rate: 'app_crash_rate',
    app_dynamic_size_bytes: 'app_dynamic_size_bytes',
    app_gpu_usage_percentage: 'app_gpu_usage_percentage',
    app_handled_exceptions: 'app_handled_exceptions',
    app_hang_count: 'app_hang_count',
    app_hang_rate: 'app_hang_rate',
    app_id: 'app_id',
    app_id_version: 'app_id_version',
    app_install_status: 'app_install_status',
    app_is_installed: 'app_is_installed',
    app_level_data: 'app_level_data',
    app_long_version: 'app_long_version',
    app_memory_usage_percentage: 'app_memory_usage_percentage',
    app_modification_date: 'app_modification_date',
    app_name: 'app_name',
    app_name_version_platform: 'app_name_version_platform',
    app_net_error_rate: 'app_net_error_rate',
    app_package_id: 'app_package_id',
    app_state_data: 'app_state_data',
    app_state_key: 'app_state_key',
    app_state_last_updated: 'app_state_last_updated',
    app_state_message: 'app_state_message',
    app_state_severity: 'app_state_severity',
    app_vendor: 'app_vendor',
    app_version: 'app_version',
    application: 'application',
    apps_with_good_score_count: 'apps_with_good_score_count',
    apps_with_good_score_percent: 'apps_with_good_score_percent',
    apteligent_app_id: 'apteligent_app_id',
    assigned_accounts_count: 'assigned_accounts_count',
    assigned_user_count: 'assigned_user_count',
    assignment_groups: 'assignment_groups',
    attributeName: 'attributeName',
    audit_event_type: 'audit_event_type',
    automation_event_type: 'automation_event_type',
    avg_app_latency_millis: 'avg_app_latency_millis',
    average_boot_time_millis: 'average_boot_time_millis',
    average_shutdown_time_millis: 'average_shutdown_time_millis',
    battery_health: 'battery_health',
    battery_level: 'battery_level',
    battery_status_charge_rate: 'battery_status_charge_rate',
    battery_status_discharge_rate: 'battery_status_discharge_rate',
    bios_timestamp: 'bios_timestamp',
    bios_version: 'bios_version',
    boot_driverload_duration_millis: 'boot_driverload_duration_millis',
    bytes_received: 'bytes_received',
    bytes_sent: 'bytes_sent',
    carrier: 'carrier',
    categories: 'categories',
    count: 'count',
    count_of_scores: 'count_of_scores',
    connection_count_score: 'connection_count_score',
    contributor: 'contributor',
    cpu_percent_processor_time: 'cpu_percent_processor_time',
    cpu_percentage: 'cpu_percentage',
    cpu_utilization: 'cpu_utilization',
    cpu_utilization_score: 'cpu_utilization_score',
    crash_first_seen: 'crash_first_seen',
    crash_id: 'crash_id',
    crash_rate: 'crash_rate',
    crashes: 'crashes',
    created_at: 'created_at',
    created_by: 'created_by',
    csp_migration_email: 'csp_migration_email',
    custom_event_type: 'custom_event_type',
    cve_id: 'cve_id',
    cve_id_list: 'cve_id_list',
    cve_published_on: 'cve_published_on',
    cvss_score: 'cvss_score',
    cvss_score_v3: 'cvss_score_v3',
    cvss_low_score: 'cvss_low_score',
    cvss_critical_score: 'cvss_critical_score',
    dap_compliant: 'dap_compliant',
    dashboard_type: 'dashboard_type',
    deployed: 'deployed',
    description: 'description',
    dest_ip: 'dest_ip',
    dest_port: 'dest_port',
    details: 'details',
    device: 'device',
    device_available_capacity: 'device_available_capacity',
    device_available_physical_memory: 'device_available_physical_memory',
    device_battery_percent: 'device_battery_percent',
    device_capacity: 'device_capacity',
    device_count: 'device_count',
    device_corp_liable: 'device_corp_liable',
    device_enrollment_user_display_name: 'device_enrollment_user_display_name',
    device_enrollment_user_email: 'device_enrollment_user_email',
    device_enrollment_user_first_name: 'device_enrollment_user_first_name',
    device_enrollment_user_last_name: 'device_enrollment_user_last_name',
    device_enrollment_user_name: 'device_enrollment_user_name',
    device_enrollment_status: 'device_enrollment_status',
    device_friendly_name: 'device_friendly_name',
    device_google_device_id: 'device_google_device_id',
    device_google_user_id: 'device_google_user_id',
    device_guid: 'device_guid',
    device_health: 'device_health',
    device_id: 'device_id',
    device_last_seen: 'device_last_seen',
    device_location_group_name: 'device_location_group_name',
    device_make: 'device_make',
    device_model: 'device_model',
    device_name: 'device_name',
    device_os_version: 'device_os_version',
    device_os_version_build: 'device_os_version_build',
    device_os_version_build_version: 'device_os_version_build_version',
    device_platform: 'device_platform',
    device_serial_number: 'device_serial_number',
    device_total_physical_memory: 'device_total_physical_memory',
    device_udid: 'device_udid',
    device_uuid: 'device_uuid',
    devices_affected: 'devices_affected',
    deviceinfo_externalipaddress: 'deviceinfo_externalipaddress',
    deviceinfo_deviceid: 'deviceinfo_deviceid',
    deviceinfo_devicename: 'deviceinfo_devicename',
    deviceinfo_deviceversion: 'deviceinfo_deviceversion',
    deviceinfo_internalipaddress: 'deviceinfo_internalipaddress',
    deviceinfo_uemid: 'deviceinfo_uemid',
    directory_type: 'directory_type',
    disk_io_bytes_sec: 'disk_io_bytes_sec',
    disk_latency: 'disk_latency',
    display_name: 'display_name',
    domain: 'domain',
    dot11_signal_quality: 'dot11_signal_quality',
    down_bytes: 'down_bytes',
    download: 'download',
    driver_name: 'driver_name',
    driver_provider: 'driver_provider',
    driver_version: 'driver_version',
    dsym_id: 'dsym_id',
    duration_millis: 'duration_millis',
    email: 'email',
    enabled: 'enabled',
    end: 'end',
    end_time: 'end_time',
    entity: 'entity',
    entity_label: 'entity_label',
    error: 'error',
    error_code: 'error_code',
    error_reason: 'error_reason',
    error_stacktrace_txt: 'error_stacktrace_txt',
    error_trace: 'error_trace',
    error_type: 'error_type',
    event_id: 'event_id',
    event_name: 'event_name',
    event_status: 'event_status',
    event_timestamp: 'event_timestamp',
    event_type: 'event_type',
    eventdescription: 'eventdescription',
    eventtime: 'eventtime',
    exception_code: 'exception_code',
    exception_offset: 'exception_offset',
    failures: 'failures',
    first_name: 'first_name',
    file_exists: 'file_exists',
    good_score: 'Good',
    hash: 'hash',
    horizon_pod_capacity_type: 'capacity_type',
    horizon_pod_id: 'pod_id',
    horizon_pod_name: 'pod_name',
    horizon_pod_type: 'pod_type',
    horizon_pool_id: 'pool_id',
    horizon_pool_name: 'pool_name',
    horizon_session_login_time: 's_logon_timestamp',
    horizon_utilization_ratio: 'horizon_utilization_ratio',
    horizon_vm_desktop_name: 'host',
    hub_notification_id: 'hub_notification_id',
    host_name: 'host_name',
    http_method: 'http_method',
    http_status_code: 'http_status_code',
    id: 'id',
    integratedServiceType: 'integratedServiceType',
    isEntitled: 'isEntitled',
    image_name: 'image_name',
    group_id: 'group_id',
    gpu_usage0: 'gpu_usage0',
    gpu_usage1: 'gpu_usage1',
    gpu_usage2: 'gpu_usage2',
    impact: 'impact',
    incidents_deployed: 'incidents_deployed',
    incidents_resolved: 'incidents_resolved',
    incident_key: 'incident_key',
    integration: 'integration',
    insight_type: 'insight_type',
    ip_version: 'ip_version',
    issue: 'issue',
    item: 'item',
    l2_rx_link_speed_bits_ps: 'l2_rx_link_speed_bits_ps',
    l2_tx_link_speed_bits_ps: 'l2_tx_link_speed_bits_ps',
    label: 'label',
    lan_average_latency: 'lan_average_latency',
    language: 'language',
    languages: 'languages',
    last_commented_at: 'last_commented_at',
    last_name: 'last_name',
    last_synced_at: 'last_synced_at',
    load_degradation_time_millis: 'load_degradation_time_millis',
    last_triggered_at: 'last_triggered_at',
    location: 'location',
    logicaldisk_free_space_mbytes: 'logicaldisk_free_space_mbytes',
    logicaldisk_free_space_percentage: 'logicaldisk_free_space_percentage',
    logon_phases: 'logon_phases',
    logon_time_in_second: 'logon_time_in_second',
    name: 'name',
    macos_update_available_os_update_name: 'macos_update_available_os_update_name',
    macos_update_build_version: 'macos_update_build_version',
    macos_update_product_key: 'macos_update_product_key',
    macos_update_update_version: 'macos_update_update_version',
    main_pathload_duration_millis: 'main_pathload_duration_millis',
    meeting: 'meeting',
    meeting_bad_score: 'meeting_bad_score',
    meeting_fair_score: 'meeting_fair_score',
    meeting_good_score: 'meeting_good_score',
    meeting_poor_score: 'meeting_poor_score',
    memory: 'memory',
    memory_percent_committed_bytes_in_use: 'memory_percent_committed_bytes_in_use',
    memory_usage_percent: 'memory_usage_percent',
    memory_usage_percentage: 'memory_usage_percentage',
    memory_utilization_percentage: 'memory_utilization_percentage',
    memory_utilization_score: 'memory_utilization_score',
    message_content: 'message_content',
    missing_symbolication_files: 'missing_symbolication_files',
    mobile_signal_strength: 'mobile_signal_strength',
    model: 'model',
    modified_at: 'modified_at',
    modified_by: 'modified_by',
    module: 'module',
    module_path: 'module_path',
    network_interface_bytes_sec: 'network_interface_bytes_sec',
    neutral_score: 'Neutral',
    nic_description: 'nic_description',
    nic_manufacturer: 'nic_manufacturer',
    nic_model: 'nic_model',
    nic_type: 'nic_type',
    num_sessions_score: 'num_sessions_score',
    objectname: 'objectname',
    object_id: 'object_id',
    object_name: 'object_name',
    object_type: 'object_type',
    observation_measure: 'observation_measure',
    observation_name: 'observation_name',
    observation_value: 'observation_value',
    device_location_group_lineage_list: 'device_location_group_lineage_list',
    org_total_enrolled_device_count: 'org_total_enrolled_device_count',
    os_major: 'os_major',
    os_minor: 'os_minor',
    os_name_version: 'os_name_version',
    os_version: 'os_version',
    os_version_name: 'os_version_name',
    outlier_type: 'outlier_type',
    overall_score: 'overall_score',
    package_id: 'package_id',
    parameters: 'parameters',
    partially_symbolicated: 'partially_symbolicated',
    partner_name: 'partner_name',
    perf_degradation: 'perf_degradation',
    pfn: 'pfn',
    physical_memory_bytes: 'physical_memory_bytes',
    pinned: 'pinned',
    platform: 'platform',
    pnp_load_duration_millis: 'pnp_load_duration_millis',
    poor_score: 'Poor',
    potential_impact: 'potential_impact',
    preshutdown_millis: 'preshutdown_millis',
    process: 'process',
    processor: 'processor',
    processor_total_processor_totaltime: 'processor_total_processor_totaltime',
    processor_total_c1_totaltime: 'processor_total_c1_totaltime',
    processor_total_c2_totaltime: 'processor_total_c2_totaltime',
    processor_total_c3_totaltime: 'processor_total_c3_totaltime',
    processor_total_dpc_totaltime: 'processor_total_dpc_totaltime',
    processor_total_idle_totaltime: 'processor_total_idle_totaltime',
    processor_total_interrupt_totaltime: 'processor_total_interrupt_totaltime',
    processor_total_privileged_totaltime: 'processor_total_privileged_totaltime',
    processor_total_user_totaltime: 'processor_total_user_totaltime',
    physicaldisk_avg_disk_bytes_transfer: 'physicaldisk_avg_disk_bytes_transfer',
    physicaldisk_avg_disk_bytes_read: 'physicaldisk_avg_disk_bytes_read',
    physicaldisk_avg_disk_bytes_write: 'physicaldisk_avg_disk_bytes_write',
    physicaldisk_idle_totaltime: 'physicaldisk_idle_totaltime',
    physicaldisk_avg_disk_read_totaltime: 'physicaldisk_avg_disk_read_totaltime',
    physicaldisk_avg_disk_write_totaltime: 'physicaldisk_avg_disk_write_totaltime',
    physicaldisk_avg_disk_sec_transfer: 'physicaldisk_avg_disk_sec_transfer',
    physicaldisk_avg_disk_sec_read: 'physicaldisk_avg_disk_sec_read',
    physicaldisk_avg_disk_sec_write: 'physicaldisk_avg_disk_sec_write',
    memory_commited_bytes_in_use: 'memory_commited_bytes_in_use',
    paging_file_usage: 'paging_file_usage',
    processor_usage_percentage: 'processor_usage_percentage',
    product_activation: 'product_activation',
    product_active: 'product_active',
    product_job_id: 'product_job_id',
    product_job_log: 'product_job_log',
    product_job_updated_date: 'product_job_updated_date',
    product_last_job_status: 'product_last_job_status',
    product_name: 'product_name',
    product_offline: 'product_offline',
    product_type: 'product_type',
    productivity_app: 'productivity_app',
    profile_assigned_version: 'profile_assigned_version',
    profile_installed_status: 'profile_installed_status',
    profile_installed_version: 'profile_installed_version',
    profile_is_active: 'profile_is_active',
    profile_is_managed: 'profile_is_managed',
    profile_name: 'profile_name',
    profile_status: 'profile_status',
    protocol: 'protocol',
    protocol_rtt: 'protocol_rtt',
    ratio: 'ratio',
    reason: 'reason',
    report_name: 'report_name',
    report_schedule_name: 'report_schedule_name',
    report_type: 'report_type',
    report_format: 'report_format',
    risk_score: 'risk_score',
    risk_indicator_names: 'risk_indicator_names',
    risk_indicators: 'risk_indicators',
    role_type: 'role_type',
    rule_id: 'rule_id',
    saved: 'saved',
    severity_priority: 'severity_priority',
    scope_name: 'scope_name',
    score_indicators: 'score_indicators',
    score: 'score',
    score_calculated_at: 'score_calculated_at',
    score_calculation_timestamp: 'score_calculation_timestamp',
    score_severity: 'score_severity',
    score_type: 'score_type',
    score_type_name: 'score_type_name',
    score_meta_data: 'score_meta_data',
    search_fields_difference: 'search_fields->difference',
    search_fields_source_object_id: 'search_fields->source_object_id',
    search_fields_source_object_type: 'search_fields->source_object_type',
    service_name: 'service_name',
    service_role: 'service_role',
    service_type: 'service_type',
    services_millis: 'services_millis',
    session: 'session',
    session_good_score: 'session.Good',
    session_neutral_score: 'session.Neutral',
    session_poor_score: 'session.Poor',
    share_view: 'share_view',
    sid: 'sid',
    sla_modified_at: 'sla_modified_at',
    sourceip: 'sourceip',
    source_directory_id: 'source_directory_id',
    source_object_id: 'source_object_id',
    source_object_type: 'source_object_type',
    stacktrace: 'stacktrace',
    stack_trace: 'stack_trace',
    stacktrace_lines: 'stacktrace_lines',
    start: 'start',
    start_time: 'start_time',
    state: 'state',
    status: 'status',
    status_check_success: 'status_check_success',
    status_score: 'status_score',
    successes: 'successes',
    survey_type: 'survey_type',
    system_session_millis: 'system_session_millis',
    system_version: 'system_version',
    tags: 'tags',
    target_count: 'target_count',
    target_entity: 'target_entity',
    target_id: 'target_id',
    target_type: 'target_type',
    test_type: 'test_type',
    total_downloads: 'total_downloads',
    total_schedules: 'total_schedules',
    thermal_zone_information_zone_temperature: 'thermal_zone_information_zone_temperature',
    threatinfo_score: 'threatinfo_score',
    threatinfo_summary: 'threatinfo_summary',
    threatinfo_threatcause_actor: 'threatinfo_threatcause_actor',
    threatinfo_threatcause_actorname: 'threatinfo_threatcause_actorname',
    threatinfo_threatcause_actorprocessppid: 'threatinfo_threatcause_actorprocessppid',
    threatinfo_threatcause_causeeventid: 'threatinfo_threatcause_causeeventid',
    threatinfo_threatcause_reason: 'threatinfo_threatcause_reason',
    threatinfo_threatcause_reputation: 'threatinfo_threatcause_reputation',
    threatinfo_threatcause_threatcategory: 'threatinfo_threatcause_threatcategory',
    threshold_86_0: 'threshold_86_0',
    threshold_92_5: 'threshold_92_5',
    title: 'title',
    uag_appliance_cpu_pct: 'uag_appliance_cpu_pct',
    uag_appliance_memory_pct: 'uag_appliance_memory_pct',
    uag_name: 'uag_name',
    up_bytes: 'up_bytes',
    url: 'url',
    user: 'user',
    user_guid: 'user_guid',
    user_location_group_lineage_list: 'user_location_group_lineage_list',
    user_name: 'user_name',
    user_productivity_apps: 'user_productivity_apps',
    user_session_millis: 'user_session_millis',
    user_top_desktop_apps: 'user_top_desktop_apps',
    username: 'username',
    values_authmethods: 'values_authmethods',
    values_failuremessage: 'values_failuremessage',
    values_success: 'values_success',
    version: 'version',
    view: 'view',
    wan_average_latency: 'wan_average_latency',
    wifi_signal_strength: 'wifi_signal_strength',
    winlogon_load_duration_millis: 'winlogon_load_duration_millis',
    winlogon_waiting_duration_millis: 'winlogon_waiting_duration_millis',
    winpatch_kb_number: 'winpatch_kb_number',
    winpatch_kb_subject: 'winpatch_kb_subject',
    winpatch_revision_id: 'winpatch_revision_id',
    winpatch_update_classification: 'winpatch_update_classification',
    winpatch_update_status: 'winpatch_update_status',
    winpatch_approval_status: 'winpatch_approval_status',
    winpatch_publish_date: 'winpatch_publish_date',
    workflow_execution_context_id: 'workflow_execution_context_id',
  },
  byField: {
    accessLevel: 'accessLevel',
    actionName: 'actionName',
    actionDescription: 'actionDescription',
    active: 'active',
    activityAt: 'activityAt',
    activityMessage: 'activityMessage',
    actorDisplayName: 'actorDisplayName',
    appVersion: 'appVersion',
    apteligentAppId: 'apteligentAppId',
    assignedUserCount: 'assignedUserCount',
    assignmentGroups: 'assignmentGroups',
    averageDataInBytes: 'averageDataInBytes',
    averageDataOutBytes: 'averageDataOutBytes',
    averageLatencyMs: 'averageLatencyMs',
    averageTime: 'averageTime',
    averageTTR: 'averageTTR',
    bucketId: 'bucketId',
    chartType: 'chartType',
    createdAt: 'createdAt',
    createdBy: 'createdBy',
    createdByDetails: 'createdByDetails',
    cronExpressionDetail: 'cronExpressionDetail',
    cspMigrationStatus: 'cspMigrationStatus',
    dataAccessPolicies: 'dataAccessPolicies',
    dataType: 'dataType',
    dataUnit: 'dataUnit',
    description: 'description',
    deviceModel: 'deviceModel',
    devicesAffected: 'devicesAffected',
    directoryType: 'directoryType',
    displayName: 'displayName',
    dsymId: 'dsymId',
    entityLabel: 'entityLabel',
    errorCode: 'errorCode',
    errorCount: 'errorCount',
    errorDetails: 'errorDetails',
    errorName: 'errorName',
    errorRate: 'errorRate',
    errorReason: 'errorReason',
    errorType: 'errorType',
    eventType: 'eventType',
    failureCount: 'failureCount',
    fileExists: 'fileExists',
    firstName: 'firstName',
    firstOccurred: 'firstOccurred',
    integration: 'integration',
    ipVersion: 'ipVersion',
    hasIncident: 'hasIncident',
    hasSurvey: 'hasSurvey',
    isHiddenInColumnSelector: 'isHiddenInColumnSelector',
    isHiddenInFilter: 'isHiddenInFilter',
    isHiddenInGroupBy: 'isHiddenInGroupBy',
    incidentsDeployed: 'incidentsDeployed',
    incidentsExistingValue: 'incidentsExistingValue',
    incidentsEvent: 'incidentsEvent',
    incidentsField: 'incidentsField',
    incidentsNewValue: 'incidentsNewValue',
    incidentsResolved: 'incidentsResolved',
    isNormalized: 'isNormalized',
    item: 'item',
    lastName: 'lastName',
    lastOccurred: 'lastOccurred',
    lastRun: 'lastRun',
    lastSyncedAt: 'lastSyncedAt',
    limit: 'limit',
    linkSharing: 'linkSharing',
    modifiedAt: 'modifiedAt',
    modifiedBy: 'modifiedBy',
    name: 'name',
    numCrashes: 'numCrashes',
    objectType: 'objectType',
    packageId: 'packageId',
    publishedOn: 'publishedOn',
    rating: 'rating',
    reportName: 'reportName',
    reportFormat: 'reportFormat',
    reportScheduleName: 'reportScheduleName',
    requestTotal: 'requestTotal',
    reportType: 'reportType',
    requestUrlHost: 'requestUrlHost',
    runHistory: 'runHistory',
    runCount: 'runCount',
    roleNames: 'roleNames',
    rolePermissions: 'rolePermissions',
    sdkInstalled: 'sdkInstalled',
    serviceName: 'serviceName',
    serviceNames: 'serviceNames',
    serviceRoleNames: 'serviceRoleNames',
    serviceTypes: 'serviceTypes',
    shareCount: 'shareCount',
    status: 'status',
    statusTime: 'statusTime',
    successCount: 'successCount',
    successRatio: 'successRatio',
    suggestionSupported: 'suggestionSupported',
    supportedOperators: 'supportedOperators',
    targetId: 'targetId',
    targetType: 'targetType',
    testType: 'testType',
    totalCount: 'totalCount',
    totalDownloads: 'totalDownloads',
    totalErrorCount: 'totalErrorCount',
    totalSchedules: 'totalSchedules',
    totalWidgets: 'totalWidgets',
    totalWorkflows: 'totalWorkflows',
    uptime: 'uptime',
    userName: 'userName',
    usersAffectedCount: 'usersAffectedCount',
    value: 'value',
    version: 'version',
  },
  byFullyQualifiedName: {
    apteligent_appload_app_id: 'apteligent.appload.app_id',
    apteligent_appload_user_name: 'apteligent.appload.user_name',
    airwatch_application_app_install_status: 'airwatch.application.app_install_status',
    airwatch_application_app_is_installed: 'airwatch.application.app_is_installed',
    airwatch_application_app_modification_date: 'airwatch.application.app_modification_date',
    airwatch_application_app_name: 'airwatch.application.app_name',
    airwatch_application_product_id: 'airwatch.application._product_id',
    airwatch_application_product_name: 'airwatch.application._product_name',
    airwatch_application_app_package_id: 'airwatch.application.app_package_id',
    airwatch_application_app_version: 'airwatch.application.app_version',
    airwatch_application_device_platform: 'airwatch.application._device_platform',
    airwatch_application_device_guid: 'airwatch.application.device_guid',
    airwatch_application_event_created_time: 'airwatch.application._event_created_time',
    airwatch_device_adp_modified_at: 'airwatch.device.adp_modified_at',
    airwatch_device_device_apple_time_zone: 'airwatch.device.device_apple_time_zone',
    airwatch_device_device_battery_serial_number: 'airwatch.device.device_battery_serial_number',
    airwatch_device_device_battery_manufacturer_date: 'airwatch.device.device_battery_manufacturer_date',
    airwatch_device_device_battery_charge_cycle: 'airwatch.device.device_battery_charge_cycle',
    airwatch_device_device_corp_liable: 'airwatch.device.device_corp_liable',
    airwatch_device_device_encryption_status: 'airwatch.device.device_encryption_status',
    airwatch_device_device_enrollment_date: 'airwatch.device.device_enrollment_date',
    airwatch_device_device_enrollment_status: 'airwatch.device.device_enrollment_status',
    airwatch_device_device_enrollment_user_name: 'airwatch.device.device_enrollment_user_name',
    airwatch_device_event_created_time: 'airwatch.device._event_created_time',
    airwatch_device_device_friendly_name: 'airwatch.device.device_friendly_name',
    airwatch_device_device_full_charge_capacity: 'airwatch.device.device_full_charge_capacity',
    airwatch_device_device_gps_latitude: 'airwatch.device.device_gps_latitude',
    airwatch_device_device_gps_longitude: 'airwatch.device.device_gps_longitude',
    airwatch_device_device_guid: 'airwatch.device.device_guid',
    airwatch_device_device_id: 'airwatch.device.device_id',
    airwatch_device_device_is_compromised: 'airwatch.device.device_is_compromised',
    airwatch_device_device_is_shared_device: 'airwatch.device.device_is_shared_device',
    airwatch_device_device_is_lost_mode_enabled: 'airwatch.device.device_is_lost_mode_enabled',
    airwatch_device_device_safe_mode: 'airwatch.device.device_safe_mode',
    airwatch_device_device_last_seen: 'airwatch.device.device_last_seen',
    airwatch_device_device_location_group_guid: 'airwatch.device.device_location_group_guid',
    airwatch_device_device_location_group_name: 'airwatch.device.device_location_group_name',
    airwatch_device_device_location_group_lineage_list: 'airwatch.device.device_location_group_lineage_list',
    airwatch_device_device_mac_address: 'airwatch.device._device_mac_address',
    airwatch_device_device_model: 'airwatch.device.device_model',
    airwatch_device_device_name: 'airwatch.device.device_name',
    airwatch_device_device_os_version: 'airwatch.device.device_os_version',
    airwatch_device_device_os_version_build_version: 'airwatch.device.device_os_version_build_version',
    airwatch_device_device_platform: 'airwatch.device._device_platform',
    airwatch_device_device_public_ip_address: 'airwatch.device.device_public_ip_address',
    airwatch_device_device_recovery_key_escrowed: 'airwatch.device.device_recovery_key_escrowed',
    airwatch_device_device_recovery_key_last_time_still_valid: 'airwatch.device.device_recovery_key_last_time_still_valid',
    airwatch_device_device_serial_number: 'airwatch.device._device_serial_number',
    airwatch_device_device_tag_name_list: 'airwatch.device.device_tag_name_list',
    airwatch_device_device_tpm_chip: 'airwatch.device.device_tpm_chip',
    airwatch_device_device_windows_bitlocker_encryption_method: 'airwatch.device.device_windows_bitlocker_encryption_method',
    airwatch_device_device_windows_bitlocker_status: 'airwatch.device.device_windows_bitlocker_status',
    airwatch_device_airwatch_device_guid: 'airwatch.device._airwatch_device_guid',
    airwatch_device_device_udid: 'airwatch.device.device_udid',
    airwatch_device_user_guid: 'airwatch.device._user_guid',
    airwatch_device_user_domain: 'airwatch.device._user_domain',
    airwatch_device_device_available_capacity: 'airwatch.device.device_available_capacity',
    airwatch_device_device_capacity: 'airwatch.device.device_capacity',
    airwatch_device_device_available_physical_memory: 'airwatch.device.device_available_physical_memory',
    airwatch_device_device_total_physical_memory: 'airwatch.device.device_total_physical_memory',
    airwatch_device_change_event_device_id: 'airwatch.device_change_event.device_id',
    airwatch_device_change_event_device_friendly_name: 'airwatch.device_change_event.device_friendly_name',
    airwatch_device_change_event_event_timestamp: 'airwatch.device_change_event.event_timestamp',
    airwatch_device_change_event_event_type: 'airwatch.device_change_event.event_type',
    airwatch_device_change_event_device_model: 'airwatch.device_change_event.device_model',
    airwatch_device_change_event_device_platform: 'airwatch.device_change_event.device_platform',
    airwatch_device_change_event_device_os_version: 'airwatch.device_change_event.device_os_version',
    airwatch_devicecustomattributes_device_guid: 'airwatch.devicecustomattributes.device_guid',
    airwatch_deviceriskscore_device_guid: 'airwatch.deviceriskscore.device_guid',
    airwatch_deviceriskscore_score_calculated_at: 'airwatch.deviceriskscore.score_calculated_at',
    airwatch_deviceriskscore_score_severity: 'airwatch.deviceriskscore.score_severity',
    airwatch_deviceriskscore_score_type_name: 'airwatch.deviceriskscore.score_type_name',
    airwatch_devicesensors_device_guid: 'airwatch.devicesensors.device_guid',
    airwatch_devicesummaryriskscore_adp_modified_at: 'airwatch.devicesummaryriskscore.adp_modified_at',
    airwatch_devicesummaryriskscore_score_severity: 'airwatch.devicesummaryriskscore.score_severity',
    airwatch_iosupdate_device_guid: 'airwatch.iosupdate.device_guid',
    airwatch_iosupdate_device_enrollment_status: 'airwatch.iosupdate.device_enrollment_status',
    airwatch_iosupdate_ios_update_status: 'airwatch.iosupdate.ios_update_status',
    airwatch_macosupdate_macos_update_available_os_update_name: 'airwatch.macosupdate.macos_update_available_os_update_name',
    airwatch_macosupdate_macos_update_product_key: 'airwatch.macosupdate.macos_update_product_key',
    airwatch_macosupdate_macos_update_build_version: 'airwatch.macosupdate.macos_update_build_version',
    airwatch_macosupdate_macos_update_update_version: 'airwatch.macosupdate.macos_update_update_version',
    airwatch_macosupdate_macos_update_is_critical: 'airwatch.macosupdate.macos_update_is_critical',
    airwatch_product_device_guid: 'airwatch.product.device_guid',
    airwatch_product_product_active: 'airwatch.product.product_active',
    airwatch_product_product_job_id: 'airwatch.product.product_job_id',
    airwatch_product_product_job_log: 'airwatch.product.product_job_log',
    airwatch_product_product_name: 'airwatch.product.product_name',
    airwatch_product_product_type: 'airwatch.product.product_type',
    airwatch_profile_adp_modified_at: 'airwatch.profile.adp_modified_at',
    airwatch_profile_device_enrollment_status: 'airwatch.profile.device_enrollment_status',
    airwatch_profile_device_enrollment_user_name: 'airwatch.profile.device_enrollment_user_name',
    airwatch_profile_profile_assigned_version: 'airwatch.profile.profile_assigned_version',
    airwatch_profile_profile_installed_status: 'airwatch.profile.profile_installed_status',
    airwatch_profile_profile_installed_version: 'airwatch.profile.profile_installed_version',
    airwatch_profile_profile_is_active: 'airwatch.profile.profile_is_active',
    airwatch_profile_profile_is_managed: 'airwatch.profile.profile_is_managed',
    airwatch_profile_profile_name: 'airwatch.profile.profile_name',
    airwatch_profile_profile_status: 'airwatch.profile.profile_status',
    airwatch_user_adp_modified_at: 'airwatch.user.adp_modified_at',
    airwatch_user_user_id: 'airwatch.user.user_id',
    airwatch_user_user_guid: 'airwatch.user._user_guid',
    airwatch_user_user_status: 'airwatch.user.user_status',
    airwatch_user_user_full_name: 'airwatch.user.user_full_name',
    airwatch_user_user_employee_id: 'airwatch.user.user_employee_id',
    airwatch_user_user_groups: 'airwatch.user.user_groups',
    airwatch_user_user_department: 'airwatch.user.user_department',
    airwatch_user_device_enrollment_user_domain: 'airwatch.user.device_enrollment_user_domain',
    airwatch_user_user_cost_center: 'airwatch.user.user_cost_center',
    airwatch_user_user_manager_full_name: 'airwatch.user.user_manager_full_name',
    airwatch_user_user_manager_email_address: 'airwatch.user.user_manager_email_address',
    airwatch_user_user_custom_attribute_1: 'airwatch.user.user_custom_attribute_1',
    airwatch_user_user_custom_attribute_2: 'airwatch.user.user_custom_attribute_2',
    airwatch_user_user_custom_attribute_3: 'airwatch.user.user_custom_attribute_3',
    airwatch_user_user_custom_attribute_4: 'airwatch.user.user_custom_attribute_4',
    airwatch_user_user_custom_attribute_5: 'airwatch.user.user_custom_attribute_5',
    airwatch_user_device_enrollment_user_name: 'airwatch.user.device_enrollment_user_name',
    airwatch_user_device_enrollment_user_first_name: 'airwatch.user.device_enrollment_user_first_name',
    airwatch_user_device_enrollment_user_last_name: 'airwatch.user.device_enrollment_user_last_name',
    airwatch_user_device_enrollment_user_email: 'airwatch.user.device_enrollment_user_email',
    airwatch_userriskscore_device_enrollment_user_email: 'airwatch.userriskscore.device_enrollment_user_email',
    airwatch_userriskscore_score_calculated_at: 'airwatch.userriskscore.score_calculated_at',
    airwatch_userriskscore_score_severity: 'airwatch.userriskscore.score_severity',
    airwatch_windowspatch_cve_id_list: 'airwatch.windowspatch.cve_id_list',
    airwatch_windowspatch_device_guid: 'airwatch.windowspatch.device_guid',
    airwatch_windowspatch_device_enrollment_status: 'airwatch.windowspatch.device_enrollment_status',
    airwatch_windowspatch_device_model: 'airwatch.windowspatch.device_model',
    airwatch_windowspatch_device_os_version: 'airwatch.windowspatch.device_os_version',
    airwatch_windowspatch_winpatch_approval_status: 'airwatch.windowspatch.winpatch_approval_status',
    airwatch_windowspatch_winpatch_kb_number: 'airwatch.windowspatch.winpatch_kb_number',
    airwatch_windowspatch_winpatch_kb_subject: 'airwatch.windowspatch.winpatch_kb_subject',
    airwatch_windowspatch_winpatch_revision_id: 'airwatch.windowspatch.winpatch_revision_id',
    airwatch_windowspatch_winpatch_update_classification: 'airwatch.windowspatch.winpatch_update_classification',
    airwatch_windowspatch_winpatch_update_status: 'airwatch.windowspatch.winpatch_update_status',

    airwatch_applicationchangeevent_airwatch_device_guid: 'airwatch.applicationchangeevent._airwatch_device_guid',
    airwatch_applicationchangeevent_device_guid: 'airwatch.applicationchangeevent.device_guid',
    airwatch_applicationchangeevent_device_friendly_name: 'airwatch.applicationchangeevent.device_friendly_name',
    airwatch_applicationchangeevent_app_package_id: 'airwatch.applicationchangeevent._app_package_id',
    airwatch_applicationchangeevent_app_name: 'airwatch.applicationchangeevent._app_name',
    airwatch_applicationchangeevent_app_version: 'airwatch.applicationchangeevent._app_version',
    airwatch_applicationchangeevent_app_install_status_reason: 'airwatch.applicationchangeevent.app_install_status_reason',
    airwatch_applicationchangeevent_app_is_installed: 'airwatch.applicationchangeevent.app_is_installed',
    airwatch_applicationchangeevent_event_timestamp: 'airwatch.applicationchangeevent.event_timestamp',
    airwatch_applicationchangeevent_device_platform: 'airwatch.applicationchangeevent._device_platform',
    airwatch_applicationchangeevent_device_enrollment_user_name: 'airwatch.applicationchangeevent.device_enrollment_user_name',
    airwatch_applicationchangeevent_change_event_type_list: 'airwatch.applicationchangeevent._change_event_type_list',

    apteligent_appload_airwatch_device_guid: 'apteligent.appload._airwatch_device_guid',
    apteligent_appload_platform: 'apteligent.appload.platform',
    apteligent_appload_system_version: 'apteligent.appload.system_version',
    apteligent_appload_app_version: 'apteligent.appload._app_version',
    apteligent_appload_device_friendly_name: 'apteligent.appload._airwatch_device__device_friendly_name',
    apteligent_appload_carrier: 'apteligent.appload.carrier',
    apteligent_appload_app_config_app_name: 'apteligent.appload.app_config_app_name',
    apteligent_appload_event_timestamp: 'apteligent.appload.event_timestamp',
    apteligent_appload_device_uuid: 'apteligent.appload.device_uuid',
    apteligent_appload_device_platform: 'apteligent.appload._device_platform',
    apteligent_appload_event_id: 'apteligent.appload.event_id',

    apteligent_battery_airwatch_device_guid: 'apteligent.battery._airwatch_device_guid',
    apteligent_battery_battery_charging_rate: 'apteligent.battery.battery_charging_rate',
    apteligent_battery_battery_charging_status: 'apteligent.battery.battery_charging_status',
    apteligent_battery_battery_level: 'apteligent.battery.battery_level',
    apteligent_battery_battery_temperature: 'apteligent.battery.battery_temperature',
    apteligent_battery_battery_type: 'apteligent.battery.battery_type',
    apteligent_battery_battery_voltage: 'apteligent.battery.battery_voltage',
    apteligent_battery_plugged_type: 'apteligent.battery.plugged_type',
    apteligent_battery_battery_status: 'apteligent.battery.battery_status',
    apteligent_battery_battery_time_since_unplugged: 'apteligent.battery.battery_time_since_unplugged',
    apteligent_battery_battery_time_since_charging: 'apteligent.battery.battery_time_since_charging',
    apteligent_battery_adp_modified_at: 'apteligent.battery.adp_modified_at',

    apteligent_network_airwatch_device_guid: 'apteligent.network._airwatch_device_guid',
    apteligent_network_adp_modified_at: 'apteligent.network.adp_modified_at',
    apteligent_network_network_connectivity_type: 'apteligent.network.network_connectivity_type',
    apteligent_network_internet_available: 'apteligent.network.internet_available',
    apteligent_network_ip_address: 'apteligent.network.ip_address',
    apteligent_network_is_multicast: 'apteligent.network.is_multicast',
    apteligent_network_is_roaming: 'apteligent.network.is_roaming',
    apteligent_network_mobile_network_type: 'apteligent.network.mobile_network_type',
    apteligent_network_mobile_radio_active: 'apteligent.network.mobile_radio_active',
    apteligent_network_mobile_signal_strength: 'apteligent.network.mobile_signal_strength',
    apteligent_network_network_download_speed_mbps: 'apteligent.network.network_download_speed_mbps',
    apteligent_network_network_upload_speed_mbps: 'apteligent.network.network_upload_speed_mbps',
    apteligent_network_vpn_connected: 'apteligent.network.vpn_connected',
    apteligent_network_wifi_configuration_status: 'apteligent.network.wifi_configuration_status',
    apteligent_network_wifi_on: 'apteligent.network.wifi_on',
    apteligent_network_wifi_frequency: 'apteligent.network.wifi_frequency',
    apteligent_network_wifi_signal_strength: 'apteligent.network.wifi_signal_strength',
    apteligent_network_ssid: 'apteligent.network.ssid',
    apteligent_network_bssid: 'apteligent.network.bssid',
    apteligent_network_latency: 'apteligent.network.latency',
    apteligent_network_jitter: 'apteligent.network.jitter',

    apteligent_device_adp_modified_at: 'apteligent.device.adp_modified_at',
    apteligent_device_airwatch_device_guid: 'apteligent.device._airwatch_device_guid',
    apteligent_device_app_id: 'apteligent.device.app_id',
    aptleigent_device_battery_temperature: 'aptleigent_device_battery_temperature',
    apteligent_device_cpu_utilization: 'apteligent.device.cpu_utilization',
    apteligent_device_device_platform: 'apteligent.device._device_platform',
    apteligent_device_event_received_timestamp: 'apteligent.device._event_received_timestamp',
    apteligent_device_memory_total_bytes: 'apteligent.device.memory_total_bytes',
    apteligent_device_time_zone: 'apteligent.device.time_zone',
    apteligent_device_process_cpu_time: 'apteligent.device.process_cpu_time',
    apteligent_device_location_service_enabled_int: 'apteligent.device.location_service_enabled_int',
    apteligent_device_location_latitude: 'apteligent.device.location_latitude',
    apteligent_device_location_longitude: 'apteligent.device.location_longitude',
    apteligent_device_cpu_cores_logical: 'apteligent.device.cpu_cores_logical',
    apteligent_device_cpu_cores_physical: 'apteligent.device.cpu_cores_physical',
    apteligent_device_manufacturer: 'apteligent.device.manufacturer',
    apteligent_device_memory_allocated_per_app_bytes: 'apteligent.device.memory_allocated_per_app_bytes',
    apteligent_device_disk_space_total_bytes: 'apteligent.device.disk_space_total_bytes',
    apteligent_device_disk_space_free_bytes: 'apteligent.device.disk_space_free_bytes',
    apteligent_device_sdcard_space_total_bytes: 'apteligent.device.sdcard_space_total_bytes',
    apteligent_device_sdcard_space_free_bytes: 'apteligent.device.sdcard_space_free_bytes',
    apteligent_device_ip_address: 'apteligent.device.ip_address',
    apteligent_device_last_boot_time: 'apteligent.device.last_boot_time',
    apteligent_device_time_since_last_boot: 'apteligent.device.time_since_last_boot',
    apteligent_device_screen_brightness: 'apteligent.device.screen_brightness',
    apteligent_device_sleep_duration: 'apteligent.device.sleep_duration',
    apteligent_device_wake_duration: 'apteligent.device.wake_duration',
    apteligent_device_uem_username: 'apteligent.device.uem_username',

    apteligent_user_flow_airwatch_device_guid: 'apteligent.user_flow._airwatch_device_guid',
    apteligent_user_flow_event_timestamp: 'apteligent.user_flow.event_timestamp',
    apteligent_user_flow_user_flow_failure_reason: 'apteligent.user_flow.user_flow_failure_reason',
    apteligent_user_flow_state_name: 'apteligent.user_flow._state_name',
    apteligent_user_flow_name: 'apteligent.user_flow.name',
    apteligent_user_flow_active_time_millis: 'apteligent.user_flow.active_time_millis',
    apteligent_user_flow_device_friendly_name: 'apteligent.user_flow._device_friendly_name',
    apteligent_user_flow_user_name: 'apteligent.user_flow.user_name',
    apteligent_user_flow_app_version: 'apteligent.user_flow._app_version',
    apteligent_user_flow_device_model: 'apteligent.user_flow.device_model',
    apteligent_user_flow_platform: 'apteligent.user_flow.platform',
    apteligent_user_flow_device_uuid: 'apteligent.user_flow.device_uuid',
    apteligent_user_flow_app_id: 'apteligent.user_flow.app_id',
    apteligent_user_flow_start_time: 'apteligent.user_flow.start_time',
    apteligent_user_flow_end_time: 'apteligent.user_flow.end_time',
    apteligent_user_flow_crash_id: 'apteligent.user_flow.crash_id',
    apteligent_user_flow_event_id: 'apteligent.user_flow.event_id',
    apteligent_user_flow_app_config_app_name: 'apteligent.user_flow.app_config_app_name',

    apteligent_net_event_app_config_app_name: 'apteligent.net_event.app_config_app_name',
    apteligent_net_event_url: 'apteligent.net_event._url',
    apteligent_net_event_http_status_code: 'apteligent.net_event.http_status_code',
    apteligent_net_event_http_status_message: 'apteligent.net_event._http_status_message',
    apteligent_net_event_latency: 'apteligent.net_event._latency',
    apteligent_net_event_bytes_received: 'apteligent.net_event.bytes_received',
    apteligent_net_event_bytes_sent: 'apteligent.net_event.bytes_sent',
    apteligent_net_event_app_version: 'apteligent.net_event._app_version',
    apteligent_net_event_system_version: 'apteligent.net_event.system_version',
    apteligent_net_event_carrier: 'apteligent.net_event.carrier',
    apteligent_net_event_device_model: 'apteligent.net_event.device_model',
    apteligent_net_event_event_received_timestamp: 'apteligent.net_event._event_received_timestamp',
    apteligent_net_event_event_timestamp: 'apteligent.net_event.event_timestamp',
    apteligent_net_event_url_host: 'apteligent.net_event._url_host',
    apteligent_net_event_url_domain: 'apteligent.net_event._url_domain',
    apteligent_net_event_http_method: 'apteligent.net_event.http_method',
    apteligent_net_event_platform: 'apteligent.net_event.platform',
    apteligent_net_event_event_id: 'apteligent.net_event.event_id',

    apteligent_net_error_airwatch_device_guid: 'apteligent.net_error._airwatch_device_guid',
    apteligent_net_error_url: 'apteligent.net_error._url',
    apteligent_net_error_event_received_timestamp: 'apteligent.net_error._event_received_timestamp',
    apteligent_net_error_net_error_msg: 'apteligent.net_error._net_error_msg',
    apteligent_net_error_bytes_sent: 'apteligent.net_error.bytes_sent',
    apteligent_net_error_app_id: 'apteligent.net_error.app_id',
    apteligent_net_error_app_version: 'apteligent.net_error._app_version',
    apteligent_net_error_system_version: 'apteligent.net_error.system_version',
    apteligent_net_error_carrier: 'apteligent.net_error.carrier',
    apteligent_net_error_device_friendly_name: 'apteligent.net_error._device_friendly_name',
    apteligent_net_error_device_model: 'apteligent.net_error.device_model',
    apteligent_net_error_device_uuid: 'apteligent.net_error.device_uuid',
    apteligent_net_error_event_timestamp: 'apteligent.net_error.event_timestamp',
    apteligent_net_error_url_host: 'apteligent.net_error._url_host',
    apteligent_net_error_http_method: 'apteligent.net_error.http_method',
    apteligent_net_error_platform: 'apteligent.net_error.platform',
    apteligent_net_error_event_id: 'apteligent.net_error.event_id',

    apteligent_custom_event_name: 'apteligent.custom_event.name',
    apteligent_custom_event_event_timestamp: 'apteligent.custom_event.event_timestamp',
    apteligent_custom_event_custom_event_type: 'apteligent.custom_event.custom_event_type',
    apteligent_custom_event_userflow_id: 'apteligent.custom_event.userflow_id',

    apteligent_grouped_crash_android_error_name: 'apteligent.grouped_crash_android._error_name',
    apteligent_grouped_crash_android_error_reason: 'apteligent.grouped_crash_android.error_reason',
    apteligent_grouped_crash_android_app_version: 'apteligent.grouped_crash_android._app_version',
    apteligent_grouped_crash_android_device_friendly_name: 'apteligent.grouped_crash_android._device_friendly_name',
    apteligent_grouped_crash_android_event_timestamp: 'apteligent.grouped_crash_android.event_timestamp',
    apteligent_grouped_crash_android_threshold: 'apteligent.grouped_crash_android.threshold_92_5',

    apteligent_grouped_crash_ios_error_name: 'apteligent.grouped_crash_ios._error_name',
    apteligent_grouped_crash_ios_error_reason: 'apteligent.grouped_crash_ios.error_reason',
    apteligent_grouped_crash_ios_app_version: 'apteligent.grouped_crash_ios._app_version',
    apteligent_grouped_crash_ios_device_friendly_name: 'apteligent.grouped_crash_ios._device_friendly_name',
    apteligent_grouped_crash_ios_event_timestamp: 'apteligent.grouped_crash_ios.event_timestamp',
    apteligent_grouped_crash_ios_stack_trace: 'apteligent.grouped_crash_ios.stack_trace',
    apteligent_grouped_crash_ios_threshold: 'apteligent.grouped_crash_ios.threshold_86_0',

    apteligent_crash_android_error_name: 'apteligent.crash_android._error_name',
    apteligent_crash_android_error_reason: 'apteligent.crash_android.error_reason',
    apteligent_crash_android_error_type: 'apteligent.crash_android.error_type',
    apteligent_crash_android_app_id: 'apteligent.crash_android.app_id',
    apteligent_crash_android_app_version: 'apteligent.crash_android._app_version',
    apteligent_crash_android_device_friendly_name: 'apteligent.crash_android._device_friendly_name',
    apteligent_crash_android_event_timestamp: 'apteligent.crash_android.event_timestamp',
    apteligent_crash_android_name: 'apteligent.crash_android.name',
    apteligent_crash_android_device_uuid: 'apteligent.crash_android.device_uuid',
    apteligent_crash_android_airwatch_device_guid: 'apteligent.crash_android._airwatch_device_guid',
    apteligent_crash_android_platform: 'apteligent.crash_android.platform',
    apteligent_crash_android_event_id: 'apteligent.crash_android.event_id',
    apteligent_crash_android_app_config_app_name: 'apteligent.crash_android.app_config_app_name',

    apteligent_crash_ios_error_name: 'apteligent.crash_ios._error_name',
    apteligent_crash_ios_error_reason: 'apteligent.crash_ios.error_reason',
    apteligent_crash_ios_app_id: 'apteligent.crash_ios.app_id',
    apteligent_crash_ios_app_version: 'apteligent.crash_ios._app_version',
    apteligent_crash_ios_device_friendly_name: 'apteligent.crash_ios._device_friendly_name',
    apteligent_crash_ios_event_timestamp: 'apteligent.crash_ios.event_timestamp',
    apteligent_crash_ios_name: 'apteligent.crash_ios.name',
    apteligent_crash_ios_error_type: 'apteligent.crash_ios.error_type',
    apteligent_crash_ios_error_code: 'apteligent.crash_ios.error_code',
    apteligent_crash_ios_device_uuid: 'apteligent.crash_ios.device_uuid',
    apteligent_crash_ios_airwatch_device_guid: 'apteligent.crash_ios._airwatch_device_guid',
    apteligent_crash_ios_platform: 'apteligent.crash_ios.platform',
    apteligent_crash_ios_event_id: 'apteligent.crash_ios.event_id',
    apteligent_crash_ios_app_config_app_name: 'apteligent.crash_ios.app_config_app_name',

    apteligent_handled_exception_ios_airwatch_device_guid: 'apteligent.handled_exception_ios._airwatch_device_guid',
    apteligent_handled_exception_ios_app_id: 'apteligent.handled_exception_ios.app_id',
    apteligent_handled_exception_ios_error_name: 'apteligent.handled_exception_ios._error_name',
    apteligent_handled_exception_ios_error_reason: 'apteligent.handled_exception_ios.error_reason',
    apteligent_handled_exception_ios_app_version: 'apteligent.handled_exception_ios._app_version',
    apteligent_handled_exception_ios_device_friendly_name: 'apteligent.handled_exception_ios._device_friendly_name',
    apteligent_handled_exception_ios_event_timestamp: 'apteligent.handled_exception_ios.event_timestamp',
    apteligent_handled_exception_ios_device_uuid: 'apteligent.handled_exception_ios.device_uuid',
    apteligent_handled_exception_ios_platform: 'apteligent.handled_exception_ios.platform',
    apteligent_handled_exception_ios_event_id: 'apteligent.handled_exception_ios.event_id',
    apteligent_handled_exception_ios_app_config_app_name: 'apteligent.handled_exception_ios.app_config_app_name',

    apteligent_handled_exception_android_airwatch_device_guid: 'apteligent.handled_exception_android._airwatch_device_guid',
    apteligent_handled_exception_android_app_id: 'apteligent.handled_exception_android.app_id',
    apteligent_handled_exception_android_error_name: 'apteligent.handled_exception_android._error_name',
    apteligent_handled_exception_android_error_reason: 'apteligent.handled_exception_android.error_reason',
    apteligent_handled_exception_android_app_version: 'apteligent.handled_exception_android._app_version',
    apteligent_handled_exception_android_device_friendly_name: 'apteligent.handled_exception_android._device_friendly_name',
    apteligent_handled_exception_android_event_timestamp: 'apteligent.handled_exception_android.event_timestamp',
    apteligent_handled_exception_android_device_uuid: 'apteligent.handled_exception_android.device_uuid',
    apteligent_handled_exception_android_platform: 'apteligent.handled_exception_android.platform',
    apteligent_handled_exception_android_event_id: 'apteligent.handled_exception_android.event_id',
    apteligent_handled_exception_android_app_config_app_name: 'apteligent.handled_exception_android.app_config_app_name',

    apteligent_plugin_exception_error_name: 'apteligent.plugin_exception._error_name',
    apteligent_plugin_exception_error_reason: 'apteligent.plugin_exception.error_reason',
    apteligent_plugin_exception_app_id: 'apteligent.plugin_exception.app_id',
    apteligent_plugin_exception_app_version: 'apteligent.plugin_exception._app_version',
    apteligent_plugin_exception_device_friendly_name: 'apteligent.plugin_exception._device_friendly_name',
    apteligent_plugin_exception_event_timestamp: 'apteligent.plugin_exception.event_timestamp',
    apteligent_plugin_exception_device_platform: 'apteligent.plugin_exception._device_platform',

    carbonblack_threat_airwatch_device_guid: 'carbonblack.threat._airwatch_device_guid',
    carbonblack_threat_alert_category: 'carbonblack.threat._alert_category',
    carbonblack_threat_device_os_version: 'carbonblack.threat._device_os_version',
    carbonblack_threat_device_platform: 'carbonblack.threat._device_platform',
    carbonblack_threat_deviceinfo_externalipaddress: 'carbonblack.threat.deviceinfo_externalipaddress',
    carbonblack_threat_deviceinfo_deviceid: 'carbonblack.threat.deviceinfo_deviceid',
    carbonblack_threat_deviceinfo_devicename: 'carbonblack.threat.deviceinfo_devicename',
    carbonblack_threat_deviceinfo_deviceversion: 'carbonblack.threat.deviceinfo_deviceversion',
    carbonblack_threat_deviceinfo_internalipaddress: 'carbonblack.threat.deviceinfo_internalipaddress',
    carbonblack_threat_deviceinfo_uemid: 'carbonblack.threat.deviceinfo_uemid',
    carbonblack_threat_event_id: 'carbonblack.threat.event_id',
    carbonblack_threat_event_id_normalized: 'carbonblack.threat._event_id',
    carbonblack_threat_event_timestamp: 'carbonblack.threat.event_timestamp',
    carbonblack_threat_event_type: 'carbonblack.threat._event_type',
    carbonblack_threat_eventdescription: 'carbonblack.threat.eventdescription',
    carbonblack_threat_eventtime: 'carbonblack.threat.eventtime',
    carbonblack_threat_partner_name: 'carbonblack.threat.partner_name',
    carbonblack_threat_threat_action: 'carbonblack.threat._threat_action',
    carbonblack_threat_threat_family: 'carbonblack.threat._threat_family',
    carbonblack_threat_threat_id: 'carbonblack.threat._threat_id',
    carbonblack_threat_threatinfo_score: 'carbonblack.threat.threatinfo_score',
    carbonblack_threat_threatinfo_summary: 'carbonblack.threat.threatinfo_summary',
    carbonblack_threat_threatinfo_threatcause_actor: 'carbonblack.threat.threatinfo_threatcause_actor',
    carbonblack_threat_threatinfo_threatcause_actorname: 'carbonblack.threat.threatinfo_threatcause_actorname',
    carbonblack_threat_threatinfo_threatcause_actorprocessppid: 'carbonblack.threat.threatinfo_threatcause_actorprocessppid',
    carbonblack_threat_threatinfo_threatcause_causeeventid: 'carbonblack.threat.threatinfo_threatcause_causeeventid',
    carbonblack_threat_threatinfo_threatcause_reason: 'carbonblack.threat.threatinfo_threatcause_reason',
    carbonblack_threat_threatinfo_threatcause_reputation: 'carbonblack.threat.threatinfo_threatcause_reputation',
    carbonblack_threat_threatinfo_threatcause_threatcategory: 'carbonblack.threat.threatinfo_threatcause_threatcategory',
    carbonblack_threat_threat_severity: 'carbonblack.threat._threat_severity',
    carbonblack_threat_timestamp: 'carbonblack.threat._timestamp',
    carbonblack_threat_vmware_threat_type: 'carbonblack.threat._vmware_threat_type',
    checkpoint_threat_event_timestamp: 'checkpoint.threat.event_timestamp',
    checkpoint_threat_partner_name: 'checkpoint.threat.partner_name',
    checkpoint_threat_threat_family: 'checkpoint.threat._threat_family',
    checkpoint_threat_threat_severity: 'checkpoint.threat._threat_severity',
    checkpoint_threat_vmware_threat_type: 'checkpoint.threat._vmware_threat_type',

    crowdstrike_threat_event_timestamp: 'crowdstrike.threat.event_timestamp',

    device_friendly_name: 'airwatch.device.device_friendly_name',
    device_enrollment_user_name: 'airwatch.device.device_enrollment_user_name',
    device_corp_liable: 'airwatch.device.device_corp_liable',
    device_last_seen: 'airwatch.device.device_last_seen',
    device_enrollment_status: 'airwatch.device.device_enrollment_status',
    device_guid: 'airwatch.device.device_guid',

    employee_experience_apps_adp_modified_at: 'employee_experience.apps.adp_modified_at',
    employee_experience_apps_agent_version: 'employee_experience.apps._agent_version',
    employee_experience_apps_airwatch_device_guid: 'employee_experience.apps._airwatch_device_guid',
    employee_experience_apps_application: 'employee_experience.apps.application',
    employee_experience_apps_application_name: 'employee_experience.apps._application_name',
    employee_experience_apps_app_path: 'employee_experience.apps.app_path',
    employee_experience_apps_app_vendor: 'employee_experience.apps.app_vendor',
    employee_experience_apps_app_version: 'employee_experience.apps._app_version',
    employee_experience_apps_data: 'employee_experience.apps.data',
    employee_experience_apps_event_name: 'employee_experience.apps.event_name',
    employee_experience_apps_event_status: 'employee_experience.apps.event_status',
    employee_experience_apps_event_timestamp: 'employee_experience.apps.event_timestamp',
    employee_experience_apps_exception_code: 'employee_experience.apps.exception_code',
    employee_experience_apps_exception_offset: 'employee_experience.apps.exception_offset',
    employee_experience_apps_device_make: 'employee_experience.apps.device_make',
    employee_experience_apps_device_model: 'employee_experience.apps.device_model',
    employee_experience_apps_device_name: 'employee_experience.apps.device_name',
    employee_experience_apps_device_platform: 'employee_experience.apps._device_platform',
    employee_experience_apps_duration_millis: 'employee_experience.apps.duration_millis',
    employee_experience_apps_hash: 'employee_experience.apps.hash',
    employee_experience_apps_load_degradation_time_millis: 'employee_experience.apps.load_degradation_time_millis',
    employee_experience_apps_module: 'employee_experience.apps.module',
    employee_experience_apps_module_path: 'employee_experience.apps.module_path',
    employee_experience_apps_name: 'employee_experience.apps.name',
    employee_experience_apps_os_version: 'employee_experience.apps.os_version',
    employee_experience_apps_pfn: 'employee_experience.apps.pfn',
    employee_experience_apps_process: 'employee_experience.apps.process',
    employee_experience_apps_publisher: 'employee_experience.apps.publisher',
    employee_experience_apps_parameters: 'employee_experience.apps.parameters',
    employee_experience_apps_reason: 'employee_experience.apps.reason',
    employee_experience_apps_user: 'employee_experience.apps.user',

    employee_experience_asset_info_airwatch_device_guid: 'employee_experience.asset_info._airwatch_device_guid',
    employee_experience_asset_info_adp_modified_at: 'employee_experience.asset_info.adp_modified_at',
    employee_experience_asset_info_agent_version: 'employee_experience.asset_info._agent_version',
    employee_experience_asset_info_device_name: 'employee_experience.asset_info.device_name',
    employee_experience_asset_info_processor: 'employee_experience.asset_info.processor',
    employee_experience_asset_info_physical_memory_bytes: 'employee_experience.asset_info.physical_memory_bytes',
    employee_experience_asset_info_bios_version: 'employee_experience.asset_info.bios_version',
    employee_experience_asset_info_bios_timestamp: 'employee_experience.asset_info.bios_timestamp',
    employee_experience_asset_info_device_os_version_friendly_name: 'employee_experience.asset_info._device_os_version_friendly_name',
    employee_experience_asset_info_os_version: 'employee_experience.asset_info.os_version',
    employee_experience_asset_info_system_type: 'employee_experience.asset_info.system_type',
    employee_experience_asset_info_time_zone: 'employee_experience.asset_info.time_zone',

    employee_experience_device_performance_airwatch_device_guid: 'employee_experience.device_performance._airwatch_device_guid',
    employee_experience_device_performance_device_make: 'employee_experience.device_performance.device_make',
    employee_experience_device_performance_device_model: 'employee_experience.device_performance.device_model',
    employee_experience_device_performance_device_name: 'employee_experience.device_performance.device_name',
    employee_experience_device_performance_device_platform: 'employee_experience.device_performance._device_platform',
    employee_experience_device_performance_event_name: 'employee_experience.device_performance.event_name',
    employee_experience_device_performance_event_timestamp: 'employee_experience.device_performance.event_timestamp',
    employee_experience_device_performance_gpu_usage0: 'employee_experience.device_performance.gpu_usage0',
    employee_experience_device_performance_memory_usage_percent: 'employee_experience.device_performance.memory_usage_percent',
    // prettier-ignore
    // eslint-disable-next-line max-len
    employee_experience_device_performance_processor_total_processor_totaltime: 'employee_experience.device_performance.processor_total_processor_totaltime',
    employee_experience_device_performance_os_version: 'employee_experience.device_performance.os_version',
    employee_experience_device_performance_user: 'employee_experience.device_performance.user',
    employee_experience_devices_airwatch_device_guid: 'employee_experience.devices._airwatch_device_guid',
    employee_experience_devices_crash_path: 'employee_experience.devices.crash_path',
    employee_experience_devices_device_make: 'employee_experience.devices.device_make',
    employee_experience_devices_device_model: 'employee_experience.devices.device_model',
    employee_experience_devices_device_name: 'employee_experience.devices.device_name',
    employee_experience_devices_device_platform: 'employee_experience.devices._device_platform',
    employee_experience_devices_duration_millis: 'employee_experience.devices.duration_millis',
    employee_experience_devices_event_name: 'employee_experience.devices.event_name',
    employee_experience_devices_event_received_timestamp: 'employee_experience.devices._event_received_timestamp',
    employee_experience_devices_event_status: 'employee_experience.devices.event_status',
    employee_experience_devices_event_timestamp: 'employee_experience.devices.event_timestamp',
    employee_experience_devices_error: 'employee_experience.devices.error',
    employee_experience_devices_load_degradation_time_millis: 'employee_experience.devices.load_degradation_time_millis',
    employee_experience_devices_main_pathload_duration_millis: 'employee_experience.devices.main_pathload_duration_millis',
    employee_experience_devices_module: 'employee_experience.devices.module',
    employee_experience_devices_os_version: 'employee_experience.devices.os_version',
    employee_experience_devices_parameters: 'employee_experience.devices.parameters',
    employee_experience_devices_perf_degradation: 'employee_experience.devices.perf_degradation',
    employee_experience_devices_pnp_load_duration_millis: 'employee_experience.devices.pnp_load_duration_millis',
    employee_experience_devices_process: 'employee_experience.devices.process',
    employee_experience_devices_reason: 'employee_experience.devices.reason',
    employee_experience_devices_services_millis: 'employee_experience.devices.services_millis',
    employee_experience_devices_stack_trace: 'employee_experience.devices.stack_trace',
    employee_experience_devices_system_session_millis: 'employee_experience.devices.system_session_millis',
    employee_experience_devices_user: 'employee_experience.devices.user',
    employee_experience_devices_user_session_millis: 'employee_experience.devices.user_session_millis',
    employee_experience_devices_winlogon_load_duration_millis: 'employee_experience.devices.winlogon_load_duration_millis',
    employee_experience_devices_winlogon_waiting_duration_millis: 'employee_experience.devices.winlogon_waiting_duration_millis',
    employee_experience_display_event_name: 'employee_experience.display.event_name',
    employee_experience_display_event_status: 'employee_experience.display.event_status',
    employee_experience_display_event_timestamp: 'employee_experience.display.event_timestamp',
    employee_experience_device_network_adapter_airwatch_device_guid: 'employee_experience.device_network_adapter._airwatch_device_guid',
    employee_experience_device_network_adapter_driver_install_date: 'employee_experience.device_network_adapter.driver_install_date',
    employee_experience_device_network_adapter_driver_name: 'employee_experience.device_network_adapter.driver_name',
    employee_experience_device_network_adapter_driver_problem: 'employee_experience.device_network_adapter.driver_problem',
    employee_experience_device_network_adapter_driver_problem_description:
      'employee_experience.device_network_adapter.driver_problem_description',
    employee_experience_device_network_adapter_driver_provider: 'employee_experience.device_network_adapter.driver_provider',
    employee_experience_device_network_adapter_driver_published_date: 'employee_experience.device_network_adapter.driver_published_date',
    employee_experience_device_network_adapter_driver_version: 'employee_experience.device_network_adapter.driver_version',
    employee_experience_device_network_adapter_nic_description: 'employee_experience.device_network_adapter.nic_description',
    employee_experience_device_network_adapter_nic_manufacturer: 'employee_experience.device_network_adapter.nic_manufacturer',
    employee_experience_device_network_adapter_nic_model: 'employee_experience.device_network_adapter.nic_model',
    employee_experience_device_network_adapter_nic_type: 'employee_experience.device_network_adapter.nic_type',
    employee_experience_device_network_adapter_physical_address: 'employee_experience.device_network_adapter.physical_address',
    employee_experience_device_hardware_bus_type: 'employee_experience.device_hardware.bus_type',
    employee_experience_device_hardware_has_problem: 'employee_experience.device_hardware.has_problem',
    employee_experience_device_hardware_driver_provider: 'employee_experience.device_hardware.driver_provider',
    employee_experience_device_hardware_driver_published_date: 'employee_experience.device_hardware.driver_published_date',
    employee_experience_device_hardware_driver_version: 'employee_experience.device_hardware.driver_version',
    employee_experience_device_hardware_hardware_name: 'employee_experience.device_hardware.hardware_name',
    employee_experience_device_hardware_hardware_id: 'employee_experience.device_hardware.hardware_id',
    employee_experience_device_hardware_hardware_type: 'employee_experience.device_hardware.hardware_type',
    employee_experience_device_hardware_hardware_manufacturer: 'employee_experience.device_hardware.hardware_manufacturer',
    employee_experience_device_hardware_is_present: 'employee_experience.device_hardware.is_present',
    employee_experience_device_hardware_last_removal_time: 'employee_experience.device_hardware.last_removal_time',
    employee_experience_device_hardware_problem_code: 'employee_experience.device_hardware.problem_code',
    employee_experience_device_hardware_problem_description: 'employee_experience.device_hardware.problem_description',

    employee_experience_device_performance_memory_pages_sec: 'employee_experience.device_performance.memory_pages_sec',
    employee_experience_device_performance_memory_page_reads_sec: 'employee_experience.device_performance.memory_page_reads_sec',
    employee_experience_device_performance_memory_page_writes_sec: 'employee_experience.device_performance.memory_page_writes_sec',
    employee_experience_device_performance_memory_page_faults_sec: 'employee_experience.device_performance.memory_page_faults_sec',
    employee_experience_device_performance_memory_cache_faults_sec: 'employee_experience.device_performance.memory_cache_faults_sec',
    employee_experience_device_performance_memory_transition_faults_sec:
      'employee_experience.device_performance.memory_transition_faults_sec',
    employee_experience_device_performance_paging_file_usage: 'employee_experience.device_performance.paging_file_usage',
    employee_experience_device_performance_memory_commited_bytes_in_use:
      'employee_experience.device_performance.memory_commited_bytes_in_use',

    employee_experience_device_performance_physicaldisk_avg_disk_bytes_queue_length:
      'employee_experience.device_performance.physicaldisk_avg_disk_bytes_queue_length',
    employee_experience_device_performance_physicaldisk_avg_disk_read_queue_length:
      'employee_experience.device_performance.physicaldisk_avg_disk_read_queue_length',
    employee_experience_device_performance_physicaldisk_avg_disk_write_queue_length:
      'employee_experience.device_performance.physicaldisk_avg_disk_write_queue_length',
    employee_experience_device_performance_physicaldisk_avg_disk_bytes_transfer:
      'employee_experience.device_performance.physicaldisk_avg_disk_bytes_transfer',
    employee_experience_device_performance_physicaldisk_avg_disk_bytes_read:
      'employee_experience.device_performance.physicaldisk_avg_disk_bytes_read',
    employee_experience_device_performance_physicaldisk_avg_disk_bytes_write:
      'employee_experience.device_performance.physicaldisk_avg_disk_bytes_write',
    employee_experience_device_performance_physicaldisk_idle_totaltime:
      'employee_experience.device_performance.physicaldisk_idle_totaltime',
    employee_experience_device_performance_physicaldisk_avg_disk_read_totaltime:
      'employee_experience.device_performance.physicaldisk_avg_disk_read_totaltime',
    employee_experience_device_performance_physicaldisk_avg_disk_write_totaltime:
      'employee_experience.device_performance.physicaldisk_avg_disk_write_totaltime',
    employee_experience_device_performance_physicaldisk_avg_disk_sec_transfer:
      'employee_experience.device_performance.physicaldisk_avg_disk_sec_transfer',
    employee_experience_device_performance_physicaldisk_avg_disk_sec_read:
      'employee_experience.device_performance.physicaldisk_avg_disk_sec_read',
    employee_experience_device_performance_physicaldisk_avg_disk_sec_write:
      'employee_experience.device_performance.physicaldisk_avg_disk_sec_write',

    employee_experience_device_performance_thermal_zone_information_zone_temperature:
      'employee_experience.device_performance.thermal_zone_information_zone_temperature',
    employee_experience_device_performance_logicaldisk_free_space_mbytes:
      'employee_experience.device_performance.logicaldisk_free_space_mbytes',
    employee_experience_device_performance_battery_status_charge_rate: 'employee_experience.device_performance.battery_status_charge_rate',
    employee_experience_device_performance_battery_status_discharge_rate:
      'employee_experience.device_performance.battery_status_discharge_rate',
    employee_experience_device_performance_gpu_usage1: 'employee_experience.device_performance.gpu_usage1',
    employee_experience_device_performance_gpu_usage2: 'employee_experience.device_performance.gpu_usage2',

    employee_experience_device_performance_processor_total_c1_totaltime:
      'employee_experience.device_performance.processor_total_c1_totaltime',
    employee_experience_device_performance_processor_total_c2_totaltime:
      'employee_experience.device_performance.processor_total_c2_totaltime',
    employee_experience_device_performance_processor_total_c3_totaltime:
      'employee_experience.device_performance.processor_total_c3_totaltime',
    employee_experience_device_performance_processor_total_dpc_totaltime:
      'employee_experience.device_performance.processor_total_dpc_totaltime',
    employee_experience_device_performance_processor_total_interrupt_totaltime:
      'employee_experience.device_performance.processor_total_interrupt_totaltime',
    employee_experience_device_performance_processor_total_interrupts_sec:
      'employee_experience.device_performance.processor_total_interrupts_sec',
    employee_experience_device_performance_processor_total_privileged_totaltime:
      'employee_experience.device_performance.processor_total_privileged_totaltime',
    employee_experience_device_performance_processor_total_idle_totaltime:
      'employee_experience.device_performance.processor_total_idle_totaltime',
    employee_experience_device_performance_processor_total_user_totaltime:
      'employee_experience.device_performance.processor_total_user_totaltime',

    employee_experience_web_app_metrics_adp_modified_at: 'employee_experience.web_app_metrics.adp_modified_at',
    employee_experience_web_app_metrics_airwatch_device_guid: 'employee_experience.web_app_metrics._airwatch_device_guid',
    employee_experience_web_app_metrics_target_url_host: 'employee_experience.web_app_metrics.target_url_host',
    employee_experience_web_app_metrics_target_url_relative: 'employee_experience.web_app_metrics.target_url_relative',
    employee_experience_web_app_metrics_protocol: 'employee_experience.web_app_metrics.protocol',

    employee_experience_horizon_user_actions_cse_names: 'employee_experience.horizon_user_actions.cse_names',
    employee_experience_horizon_user_actions_duration_millis: 'employee_experience.horizon_user_actions.duration_millis',
    employee_experience_horizon_user_actions_end_time: 'employee_experience.horizon_user_actions.end_time',
    employee_experience_horizon_user_actions_event_name: 'employee_experience.horizon_user_actions.event_name',
    employee_experience_horizon_user_actions_gpo_names: 'employee_experience.horizon_user_actions.gpo_names',
    employee_experience_horizon_user_actions_is_async_processing: 'employee_experience.horizon_user_actions.is_async_processing',
    employee_experience_horizon_user_actions_name: 'employee_experience.horizon_user_actions.name',
    employee_experience_horizon_user_actions_preprocessing_duration_millis:
      'employee_experience.horizon_user_actions.preprocessing_duration_millis',
    employee_experience_horizon_user_actions_session_uuid: 'employee_experience.horizon_user_actions.session_uuid',
    employee_experience_horizon_user_actions_start_time: 'employee_experience.horizon_user_actions.start_time',

    employee_experience_horizon_apps_device_hostname: 'employee_experience.horizon_apps._device_hostname',
    employee_experience_horizon_apps_device_platform: 'employee_experience.horizon_apps._device_platform',
    employee_experience_horizon_apps_module: 'employee_experience.horizon_apps.module',
    employee_experience_horizon_apps_exception_code: 'employee_experience.horizon_apps.exception_code',
    employee_experience_horizon_apps_name: 'employee_experience.horizon_apps.name',
    employee_experience_horizon_apps_user: 'employee_experience.horizon_apps.user',
    employee_experience_horizon_resource_consumption_device_hostname: 'employee_experience.horizon_resource_consumption._device_hostname',
    employee_experience_horizon_resource_consumption_disk_io_bytes_sec:
      'employee_experience.horizon_resource_consumption.disk_io_bytes_sec',
    employee_experience_horizon_resource_consumption_horizon_session_user:
      'employee_experience.horizon_resource_consumption.horizon_session_user',
    employee_experience_horizon_resource_consumption_memory_usage_percentage:
      'employee_experience.horizon_resource_consumption.memory_usage_percentage',
    employee_experience_horizon_resource_consumption_name: 'employee_experience.horizon_resource_consumption.name',
    employee_experience_horizon_resource_consumption_processor_usage_percentage:
      'employee_experience.horizon_resource_consumption.processor_usage_percentage',
    employee_experience_horizon_resource_consumption_session_uuid: 'employee_experience.horizon_resource_consumption.session_uuid',
    employee_experience_horizon_resource_consumption_vm_id: 'employee_experience.horizon_resource_consumption._vm_id',

    employee_experience_net_event_adp_modified_at: 'employee_experience.net_event.adp_modified_at',
    employee_experience_net_event_airwatch_device_guid: 'employee_experience.net_event._airwatch_device_guid',
    employee_experience_net_event_device_model: 'employee_experience.net_event.device_model',
    employee_experience_net_event_device_name: 'employee_experience.net_event.device_name',
    employee_experience_net_event_device_os_version: 'employee_experience.net_event._device_os_version',
    employee_experience_net_event_device_platform: 'employee_experience.net_event._device_platform',
    employee_experience_net_event_event_friendly_name: 'employee_experience.net_event._event_friendly_name',
    employee_experience_net_event_event_status: 'employee_experience.net_event.event_status',
    employee_experience_net_event_event_timestamp: 'employee_experience.net_event.event_timestamp',
    employee_experience_net_event_dot11_auth_type: 'employee_experience.net_event.dot11_auth_type',
    employee_experience_net_event_dot11_bss_wps_enabled: 'employee_experience.net_event.dot11_bss_wps_enabled',
    employee_experience_net_event_dot11_encryption: 'employee_experience.net_event.dot11_encryption',
    employee_experience_net_event_dot11_frequency: 'employee_experience.net_event.dot11_frequency',
    employee_experience_net_event_dot11_mode: 'employee_experience.net_event.dot11_mode',
    employee_experience_net_event_dot11_rssi: 'employee_experience.net_event.dot11_rssi',
    employee_experience_net_event_dot11_security_standard: 'employee_experience.net_event.dot11_security_standard',
    employee_experience_net_event_dot11_signal_quality: 'employee_experience.net_event.dot11_signal_quality',
    employee_experience_net_event_dot11_ssid: 'employee_experience.net_event.dot11_ssid',
    employee_experience_net_event_dot11_wifi_generation: 'employee_experience.net_event.dot11_wifi_generation',
    employee_experience_net_event_driver_name: 'employee_experience.net_event.driver_name',
    employee_experience_net_event_ipv4_addresses: 'employee_experience.net_event.ipv4_addresses',
    employee_experience_net_event_ipv4_dhcp_server: 'employee_experience.net_event.ipv4_dhcp_server',
    employee_experience_net_event_ipv4_dns_servers: 'employee_experience.net_event.ipv4_dns_servers',
    employee_experience_net_event_ipv6_addresses: 'employee_experience.net_event.ipv6_addresses',
    employee_experience_net_event_l2_rx_link_speed_bits_ps: 'employee_experience.net_event.l2_rx_link_speed_bits_ps',
    employee_experience_net_event_l2_tx_link_speed_bits_ps: 'employee_experience.net_event.l2_tx_link_speed_bits_ps',
    employee_experience_net_event_nic_description: 'employee_experience.net_event.nic_description',
    employee_experience_net_event_nic_type: 'employee_experience.net_event.nic_type',
    employee_experience_net_event_physical_address: 'employee_experience.net_event.physical_address',
    employee_experience_net_event_wifi_signal_range: 'employee_experience.net_event._wifi_signal_range',
    employee_experience_resource_consumption_airwatch_device_guid: 'employee_experience.resource_consumption._airwatch_device_guid',
    employee_experience_resource_consumption_device_model: 'employee_experience.resource_consumption.device_model',
    employee_experience_resource_consumption_device_name: 'employee_experience.resource_consumption.device_name',
    employee_experience_resource_consumption_name: 'employee_experience.resource_consumption.name',
    employee_experience_resource_consumption_user: 'employee_experience.resource_consumption.user',
    employee_experience_user_actions_airwatch_device_guid: 'employee_experience.user_actions._airwatch_device_guid',
    employee_experience_user_actions_event_name: 'employee_experience.user_actions.event_name',
    employee_experience_user_actions_event_timestamp: 'employee_experience.user_actions.event_timestamp',
    employee_experience_user_actions_event_status: 'employee_experience.user_actions.event_status',
    employee_experience_user_actions_device_name: 'employee_experience.user_actions.device_name',
    employee_experience_user_actions_os_version: 'employee_experience.user_actions.os_version',
    employee_experience_user_actions_user: 'employee_experience.user_actions.user',
    employee_experience_user_actions_logon_type: 'employee_experience.user_actions._logon_type',
    employee_experience_user_actions_sid: 'employee_experience.user_actions.sid',
    employee_experience_user_actions_duration_millis: 'employee_experience.user_actions.duration_millis',
    employee_experience_user_actions_device_platform: 'employee_experience.user_actions._device_platform',
    employee_experience_user_actions_device_model: 'employee_experience.user_actions.device_model',
    employee_experience_user_actions_airwatch_device__device_location_group_name:
      'employee_experience.user_actions._airwatch_device__device_location_group_name',
    employee_experience_user_actions_result: 'employee_experience.user_actions.result',
    employee_experience_services_airwatch_device_guid: 'employee_experience.services._airwatch_device_guid',
    employee_experience_services_event_name: 'employee_experience.services.event_name',
    employee_experience_services_event_timestamp: 'employee_experience.services.event_timestamp',
    employee_experience_services_event_status: 'employee_experience.services.event_status',
    employee_experience_services_device_name: 'employee_experience.services.device_name',
    employee_experience_services_os_version: 'employee_experience.services.os_version',
    employee_experience_services_service_name: 'employee_experience.services.service_name',
    employee_experience_services_image_name: 'employee_experience.services.image_name',
    employee_experience_services_duration_millis: 'employee_experience.services.duration_millis',
    employee_experience_os_updates_airwatch_device_guid: 'employee_experience.os_updates._airwatch_device_guid',
    employee_experience_os_updates_duration_millis: 'employee_experience.os_updates.duration_millis',
    employee_experience_os_updates_event_name: 'employee_experience.os_updates.event_name',
    employee_experience_os_updates_event_timestamp: 'employee_experience.os_updates.event_timestamp',
    employee_experience_os_updates_event_status: 'employee_experience.os_updates.event_status',
    employee_experience_os_updates_device_name: 'employee_experience.os_updates.device_name',
    employee_experience_os_updates_os_version: 'employee_experience.os_updates.os_version',
    employee_experience_os_updates_revision_number: 'employee_experience.os_updates.revision_number',
    employee_experience_os_updates_title: 'employee_experience.os_updates.title',
    horizon_connection_server_general_titanchangeevent_connection_server_id:
      'horizon.connection_server_general_titanchangeevent.connection_server_id',
    horizon_connection_server_general_titanchangeevent_event_received_timestamp:
      'horizon.connection_server_general_titanchangeevent._event_received_timestamp',
    horizon_connection_server_general_titanchangeevent_connection_server_status:
      'horizon.connection_server_general_titanchangeevent.connection_server_status',
    horizon_pod_deployment_type: 'horizon.pod._deployment_type',
    horizon_pod_event_pod_id: 'horizon.pod_event.pod_id',
    horizon_pool_event_pod_id: 'horizon.pool_event.pod_id',
    horizon_pod_pod_id: 'horizon.pod.pod_id',
    horizon_pod_name: 'horizon.pod.pod_name',
    horizon_pool_name: 'horizon.pool.pool_name',
    horizon_session_bandwidth: 'horizon.session_snapshot._protocol_bandwidth',
    horizon_session_client_ip: 'horizon.session_snapshot.view_client_ip_address',
    horizon_session_client_type: 'horizon.session_snapshot._view_client_type',
    horizon_session_cpu_percent_processor_time: 'horizon.session_snapshot.cpu_percent_processor_time',
    horizon_session_disk_reads_per_sec: 'horizon.session_snapshot.session_disk_reads_per_sec',
    horizon_session_disk_transfers_per_sec: 'horizon.session_snapshot.disk_transfers_per_sec',
    horizon_session_disk_writes_per_sec: 'horizon.session_snapshot.session_disk_writes_per_sec',
    horizon_session_healthy_status: 'horizon.session_snapshot._session_health',
    horizon_session_host: 'horizon.session_snapshot.host',
    horizon_session_interactive_duration: 'horizon.session_snapshot.s_interactive_dur',
    horizon_session_login_time: 'horizon.session_snapshot.s_logon_timestamp',
    horizon_session_logon_duration: 'horizon.session_snapshot.session_vdms_desktop_agent_logon_time_in_second',
    horizon_session_memory_percent_committed_bytes_in_use: 'horizon.session_snapshot.memory_percent_committed_bytes_in_use',
    horizon_session_packet_loss: 'horizon.session_snapshot._protocol_packet_loss_percentage',
    horizon_session_pool: 'horizon.session_snapshot.pool',
    horizon_session_pool_type: 'horizon.session_snapshot._session_pool_type',
    horizon_session_profile_load_duration: 'horizon.session_snapshot.s_prof_load_t_s',
    horizon_session_protocol: 'horizon.session_snapshot.view_client_protocol',
    horizon_session_protocol_frame_rate: 'horizon.session_snapshot._protocol_frame_rate',
    horizon_session_protocol_latency: 'horizon.session_snapshot._protocol_latency',
    horizon_session_shell_load_time: 'horizon.session_snapshot.s_shl_st_t_s',
    horizon_session_status: 'horizon.session_snapshot.s_status',
    horizon_session_type: 'horizon.session_snapshot.s_type',
    horizon_session_user: 'horizon.session_snapshot.horizon_session_user',
    horizon_session_uuid: 'horizon.session_snapshot.session_uuid',
    horizon_session_historical_event_timestamp: 'horizon.session._event_timestamp',
    horizon_session_historical_host: 'horizon.session.host',
    horizon_session_historical_pod_id: 'horizon.session.pod_id',
    horizon_session_historical_pool: 'horizon.session.pool',
    horizon_session_historical_pool_id: 'horizon.session.poolid',
    horizon_session_historical_session_id: 'horizon.session.session_id',
    horizon_session_historical_session_uuid: 'horizon.session.session_uuid',
    horizon_session_daas_vm_id: 'horizon.session.daas_vm_id',
    horizon_session_s_status: 'horizon.session.s_status',
    horizon_session_horizon_session_user: 'horizon.session.horizon_session_user',
    horizon_session_view_client_ip_address: 'horizon.session.view_client_ip_address',
    horizon_session_client_version: 'horizon.session.client_version',
    horizon_session_pod_name: 'horizon.session.pod_name',
    horizon_session_historical_user: 'horizon_session_user',
    horizon_session_titan_event_timestamp: 'horizon.session_titan.event_timestamp',
    horizon_session_titan_s_logon_timestamp: 'horizon.session_titan.s_logon_timestamp',
    horizon_session_titan_client_os_version: 'horizon.session_titan.client_os_version',
    horizon_session_titan_edge_name: 'horizon.session_titan.edge_name',
    horizon_session_titan_horizon_session_user: 'horizon.session_titan.horizon_session_user',
    horizon_session_titan_host: 'horizon.session_titan.host',
    horizon_session_titan_view_client_protocol: 'horizon.session_titan.view_client_protocol',
    horizon_session_titan_s_status: 'horizon.session_titan.s_status',
    horizon_session_titan_session_uuid: 'horizon.session_titan.session_uuid',
    horizon_session_titan_template_name: 'horizon.session_titan.template_name',
    horizon_session_titan_view_client_ip_address: 'horizon.session_titan.view_client_ip_address',
    horizon_session_titan_vm_id: 'horizon.session_titan.vm_id',
    horizon_session_titan_dot11_ssid: 'horizon.session_titan.dot11_ssid',
    horizon_pool_pool_id: 'horizon.pool.pool_id',
    horizon_uag_appliance_info_titanchangeevent_uag_appliance_id: 'horizon.uag_appliance_info_titanchangeevent.uag_appliance_id',
    horizon_uag_appliance_info_titanchangeevent_event_received_timestamp:
      'horizon.uag_appliance_info_titanchangeevent._event_received_timestamp',
    horizon_uag_appliance_info_titanchangeevent_uag_appliance_health_status:
      'horizon.uag_appliance_info_titanchangeevent.uag_appliance_health_status',
    horizon_vm_cpu_percent: 'horizon.vm.cpu_percent_processor_time',
    horizon_vm_cpu_level: 'horizon.vm._cpu_utilization_level',
    horizon_vm_desktop_name: 'horizon.vm.host',
    horizon_vm_disk_iops: 'horizon.vm.disk_transfers_per_sec',
    horizon_vm_disk_latency: 'horizon.vm.avg_disk_ms_per_transfer',
    horizon_vm_health_status: 'horizon.vm._vm_health',
    horizon_vm_memory_percent: 'horizon.vm.memory_percent_committed_bytes_in_use',
    horizon_vm_network_bandwidth: 'horizon.vm.network_bandwidth_kilobytes_per_second',
    horizon_edge_titan_edge_id: 'horizon.edge_titan.edge_id',
    horizon_edge_titan_edge_name: 'horizon.edge_titan.edge_name',
    horizon_edge_titan_edge_type: 'horizon.edge_titan.edge_type',
    horizon_edge_titan__edge_type: 'horizon.edge_titan._edge_type',
    horizon_edge_titan_site_id: 'horizon.edge_titan.site_id',
    horizon_site_titan_site_id: 'horizon.site_titan.site_id',
    horizon_site_titan_site_name: 'horizon.site_titan.site_name',
    horizon_session_snapshot_titan_edge_id: 'horizon.session_snapshot_titan.edge_id',
    horizon_session_snapshot_titan_edge_name: 'horizon.session_snapshot_titan.edge_name',
    horizon_session_snapshot_titan_edge_type: 'horizon.session_snapshot_titan.edge_type',
    horizon_session_snapshot_titan_client_ip: 'horizon.session_snapshot_titan.view_client_ip_address',
    horizon_session_snapshot_titan_client_type: 'horizon.session_snapshot_titan._view_client_type',
    horizon_session_snapshot_titan_host: 'horizon.session_snapshot_titan.host',
    horizon_session_snapshot_titan_interactive_duration: 'horizon.session_snapshot_titan.s_interactive_dur',
    horizon_session_snapshot_titan_login_time: 'horizon.session_snapshot_titan.s_logon_timestamp',
    horizon_session_snapshot_titan_logon_duration: 'horizon.session_snapshot_titan.s_da_logon_t_s',
    horizon_session_snapshot_titan_pcoip_bandwidth: 'horizon.session_snapshot_titan.pcoip_bandwidth',
    horizon_session_snapshot_titan_pcoip_encoded_frame_rate: 'horizon.session_snapshot_titan.pcoip_encoded_frame_rate',
    horizon_session_snapshot_titan_pcoip_latency: 'horizon.session_snapshot_titan._pcoip_latency',
    horizon_session_snapshot_titan_profile_load_duration: 'horizon.session_snapshot_titan.s_prof_load_t_s',
    horizon_session_snapshot_titan_protocol: 'horizon.session_snapshot_titan.view_client_protocol',
    horizon_session_snapshot_titan_protocol_latency: 'horizon.session_snapshot_titan._protocol_latency',
    horizon_session_snapshot_titan_protocol_frame_rate: 'horizon.session_snapshot_titan._protocol_frame_rate',
    horizon_session_snapshot_titan_packet_loss: 'horizon.session_snapshot_titan._protocol_packet_loss_percentage',
    horizon_session_snapshot_titan_shell_load_time: 'horizon.session_snapshot_titan.s_shl_st_t_s',
    horizon_session_snapshot_titan_session_health: 'horizon.session_snapshot_titan._session_health',
    horizon_session_snapshot_titan_s_type: 'horizon.session_snapshot_titan.s_type',
    horizon_session_snapshot_titan_status: 'horizon.session_snapshot_titan.s_status',
    horizon_session_snapshot_titan_template_name: 'horizon.session_snapshot_titan.template_name',
    horizon_session_snapshot_titan_template_type: 'horizon.session_snapshot_titan.template_type',
    horizon_session_snapshot_titan_user: 'horizon.session_snapshot_titan.horizon_session_user',
    horizon_session_titan_edge_id: 'horizon.session_titan.edge_id',
    horizon_session_titan_template_id: 'horizon.session_titan.template_id',
    horizon_template_titan_edge_id: 'horizon.template_titan.edge_id',
    horizon_template_titan_template_id: 'horizon.template_titan.template_id',
    horizon_template_titan_template_name: 'horizon.template_titan.template_name',
    horizon_vm_titan_cpu_percent: 'horizon.vm_titan.cpu_percent_processor_time',
    horizon_vm_titan_cpu_percent_processor_time: 'horizon.vm_titan.cpu_percent_processor_time',
    horizon_vm_titan_desktop_name: 'horizon.vm_titan.host',
    horizon_vm_titan_disk_iops: 'horizon.vm_titan.disk_transfers_per_sec',
    horizon_vm_titan_disk_latency: 'horizon.vm_titan.avg_disk_ms_per_transfer',
    horizon_vm_titan_health_status: 'horizon.vm_titan._vm_health',
    horizon_vm_titan_memory_percent: 'horizon.vm_titan.memory_percent_committed_bytes_in_use',
    horizon_vm_titan_network_bandwidth: 'horizon.vm_titan.network_bandwidth_kilobytes_per_second',
    horizon_vm_titan_pool_template_name: 'horizon.vm_titan.template_name',

    hub_notification_action_device_guid: 'hub.notification_action.device_guid',
    hub_notification_action_platform: 'hub.notification_action.platform',

    hub_notification_adp_modified_at: 'hub.notification.adp_modified_at',
    hub_notification_audience_type: 'hub.notification.audience_type',
    hub_notification_notification_description: 'hub.notification.notification_description',
    hub_notification_notification_id: 'hub.notification.notification_id',
    hub_notification_notification_name: 'hub.notification.notification_name',
    hub_notification_notification_type: 'hub.notification.notification_type',
    hub_notification_priority: 'hub.notification.priority',

    hub_survey_adp_modified_at: 'hub.survey.adp_modified_at',
    hub_survey_event_id_normalized: 'hub.survey._event_id',
    hub_survey_event_timestamp: 'hub.survey.event_timestamp',
    hub_survey_hub_user_guid: 'hub.survey.hub_user_guid',
    hub_survey_responses: 'hub.survey.responses',
    hub_survey_survey_action: 'hub.survey.survey_action',
    hub_survey_survey_id: 'hub.survey.survey_id',
    hub_survey_username: 'hub.survey.username',

    idm_launch_actorusername: 'idm.launch.actorusername',
    idm_launch_actordomain: 'idm.launch.actordomain',
    idm_launch_objectname: 'idm.launch.objectname',
    idm_launch_values_success: 'idm.launch.values_success',
    idm_launch_values_failuremessage: 'idm.launch.values_failuremessage',
    idm_launch_sourceip: 'idm.launch.sourceip',
    idm_launch_event_id: 'idm.launch.event_id',
    idm_launch_event_type: 'idm.launch.event_type',
    idm_launch_event_timestamp: 'idm.launch.event_timestamp',
    idm_login_actorusername: 'idm.login.actorusername',
    idm_login_actordomain: 'idm.login.actordomain',
    idm_login_objectname: 'idm.login.objectname',
    idm_login_values_success: 'idm.login.values_success',
    idm_login_values_authmethods: 'idm.login.values_authmethods',
    idm_login_values_failuremessage: 'idm.login.values_failuremessage',
    idm_login_sourceip: 'idm.login.sourceip',
    idm_login_event_id: 'idm.login.event_id',
    idm_login_event_type: 'idm.login.event_type',
    idm_login_event_timestamp: 'idm.login.event_timestamp',
    idm_logout_actorusername: 'idm.logout.actorusername',
    idm_logout_actordomain: 'idm.logout.actordomain',
    idm_logout_values_success: 'idm.logout.values_success',
    idm_logout_values_authmethods: 'idm.logout.values_authmethods',
    idm_logout_sourceip: 'idm.logout.sourceip',
    idm_logout_event_type: 'idm.logout.event_type',
    idm_logout_event_timestamp: 'idm.logout.event_timestamp',
    internal_device_patch_status_cvss_score_v2: 'internal.device_patch_status.cvss_score_v2',
    internal_device_patch_status_device_guid: 'internal.device_patch_status.device_guid',
    internal_device_patch_status_device_platform: 'internal.device_patch_status.device_platform',
    internal_device_patch_status_kb_number: 'internal.device_patch_status.kb_number',
    internal_device_patch_status_patch_status: 'internal.device_patch_status.patch_status',
    // experiencescore_desktop_app
    internal_experiencescore_desktop_app_adp_modified_at: 'internal.experiencescore_desktop_app.adp_modified_at',
    internal_experiencescore_desktop_app_application_name: 'internal.experiencescore_desktop_app._application_name',
    internal_experiencescore_desktop_app_app_crash_rate: 'internal.experiencescore_desktop_app.app_crash_rate',
    internal_experiencescore_desktop_app_app_crash_rate_score: 'internal.experiencescore_desktop_app._app_crash_rate_score',
    internal_experiencescore_desktop_app_app_hang_count: 'internal.experiencescore_desktop_app.app_hang_count',
    internal_experiencescore_desktop_app_app_hang_rate_score: 'internal.experiencescore_desktop_app._app_hang_rate_score',
    internal_experiencescore_desktop_app_app_version: 'internal.experiencescore_desktop_app._app_version',
    internal_experiencescore_desktop_app_app_name_version_platform: 'internal.experiencescore_desktop_app.app_name_version_platform',
    internal_experiencescore_desktop_app_created_at: 'internal.experiencescore_desktop_app.created_at',
    internal_experiencescore_desktop_app_desktop_app_overall_score: 'internal.experiencescore_desktop_app._desktop_app_overall_score',
    internal_experiencescore_desktop_app_device_platform: 'internal.experiencescore_desktop_app._device_platform',
    internal_experiencescore_desktop_app_event_timestamp: 'internal.experiencescore_desktop_app.event_timestamp',
    internal_experiencescore_desktop_app_impact: 'internal.experiencescore_desktop_app.impact',
    internal_experiencescore_desktop_app_potential_impact: 'internal.experiencescore_desktop_app.potential_impact',
    internal_experiencescore_desktop_app_app_name: 'internal.experiencescore_desktop_app.app_name',
    internal_experiencescore_desktop_app_score_calculation_timestamp: 'internal.experiencescore_desktop_app.score_calculation_timestamp',
    internal_experiencescore_desktop_app_app_hang_rate: 'internal.experiencescore_desktop_app.app_hang_rate',
    internal_experiencescore_desktop_app_app_crash_count: 'internal.experiencescore_desktop_app.app_crash_count',
    internal_experiencescore_desktop_app_app_level_data: 'internal.experiencescore_desktop_app.app_level_data',
    internal_horizon_experiencescore_connection_server_connection_server_id:
      'internal.horizon_experiencescore_connection_server.connection_server_id',
    internal_horizon_experiencescore_uag_appliance_uag_appliance_id: 'internal.horizon_experiencescore_uag_appliance.uag_appliance_id',
    //horizon_experiencescore_session
    internal_horizon_experiencescore_app_app_name: 'internal.horizon_experiencescore_app.app_name',
    internal_horizon_experiencescore_app_desktop_app_overall_score: 'internal.horizon_experiencescore_app._desktop_app_overall_score',
    internal_horizon_experiencescore_app_impact: 'internal.horizon_experiencescore_app.impact',
    internal_horizon_experiencescore_app_org_total_enrolled_device_count:
      'internal.horizon_experiencescore_app.org_total_enrolled_device_count',
    internal_horizon_experiencescore_app_potential_impact: 'internal.horizon_experiencescore_app.potential_impact',
    // horizon_experiencescore_session_logon
    internal_horizon_experiencescore_session_logon_logon_experience_score:
      'internal.horizon_experiencescore_session_logon._logon_duration_score',
    internal_horizon_experiencescore_session_logon_deployment_name: 'internal.horizon_experiencescore_session_logon.deployment_name',
    internal_horizon_experiencescore_session_logon_horizon_session_user:
      'internal.horizon_experiencescore_session_logon.horizon_session_user',
    internal_horizon_experiencescore_session_logon_logon_duration: 'internal.horizon_experiencescore_session_logon.logon_duration',
    internal_horizon_experiencescore_session_logon_gpo_load_duration: 'internal.horizon_experiencescore_session_logon.gpo_load_duration',
    internal_horizon_experiencescore_session_logon_pool_name: 'internal.horizon_experiencescore_session_logon.pool_name',
    internal_horizon_experiencescore_session_logon_profile_load_duration:
      'internal.horizon_experiencescore_session_logon.profile_load_duration',
    internal_horizon_experiencescore_session_logon_session_uuid: 'internal.horizon_experiencescore_session_logon.session_uuid',
    internal_horizon_experiencescore_session_logon_shell_load_duration:
      'internal.horizon_experiencescore_session_logon.shell_load_duration',
    internal_horizon_experiencescore_session_logon_logon_script_duration:
      'internal.horizon_experiencescore_session_logon.logon_script_duration',
    internal_horizon_experiencescore_session_logon_guest_os_logon_other_duration:
      'internal.horizon_experiencescore_session_logon.guest_os_logon_other_duration',
    internal_horizon_experiencescore_session_logon_s_logon_timestamp: 'internal.horizon_experiencescore_session_logon.s_logon_timestamp',
    internal_horizon_experiencescore_session_logon_duration_score: 'internal.horizon_experiencescore_session._logon_duration_score',
    internal_horizon_experiencescore_session_packet_loss_score: 'internal.horizon_experiencescore_session._packet_loss_score',
    internal_horizon_experiencescore_session_protocol_rtt_score: 'internal.horizon_experiencescore_session._protocol_rtt_score',
    internal_horizon_experiencescore_session_cpu_consumption_score: 'internal.horizon_experiencescore_session._cpu_consumption_score',
    internal_horizon_experiencescore_session_memory_consumption_score: 'internal.horizon_experiencescore_session._memory_consumption_score',
    internal_horizon_experiencescore_session_disk_latency_score: 'internal.horizon_experiencescore_session._disk_latency_score',
    internal_horizon_experiencescore_session_disk_iops_score: 'internal.horizon_experiencescore_session._disk_iops_score',
    internal_horizon_experiencescore_session_logon_duration: 'internal.horizon_experiencescore_session.logon_duration',
    internal_horizon_experiencescore_session_packet_loss_rate: 'internal.horizon_experiencescore_session.packet_loss_rate',
    internal_horizon_experiencescore_session_protocol_rtt: 'internal.horizon_experiencescore_session.protocol_rtt',
    internal_horizon_experiencescore_session_cpu_consumption: 'internal.horizon_experiencescore_session.cpu_consumption',
    internal_horizon_experiencescore_session_memory_consumption: 'internal.horizon_experiencescore_session.memory_consumption',
    internal_horizon_experiencescore_session_disk_latency: 'internal.horizon_experiencescore_session.disk_latency',
    internal_horizon_experiencescore_session_disk_iops: 'internal.horizon_experiencescore_session.disk_iops',
    internal_horizon_experiencescore_session_dot11_score: 'internal.horizon_experiencescore_session._dot11_score',
    internal_horizon_experiencescore_session_dot11_signal_quality: 'internal.horizon_experiencescore_session.dot11_signal_quality',
    internal_horizon_experiencescore_session_lan_average_latency: 'internal.horizon_experiencescore_session.lan_average_latency',
    internal_horizon_experiencescore_session_lan_average_latency_score:
      'internal.horizon_experiencescore_session._lan_average_latency_score',
    internal_horizon_experiencescore_session_lan_packet_loss_rate: 'internal.horizon_experiencescore_session.lan_packet_loss_rate',
    internal_horizon_experiencescore_session_lan_packet_loss_rate_score:
      'internal.horizon_experiencescore_session._lan_packet_loss_rate_score',
    internal_horizon_experiencescore_session_wan_average_latency_score:
      'internal.horizon_experiencescore_session._wan_average_latency_score',
    internal_horizon_experiencescore_session_wan_average_latency: 'internal.horizon_experiencescore_session.wan_average_latency',
    internal_horizon_experiencescore_session_wan_packet_loss_rate: 'internal.horizon_experiencescore_session.wan_packet_loss_rate',
    internal_horizon_experiencescore_session_wan_packet_loss_rate_score:
      'internal.horizon_experiencescore_session._wan_packet_loss_rate_score',
    internal_horizon_experiencescore_session_client_device_name: 'internal.horizon_experiencescore_session.client_device_name',
    internal_horizon_experiencescore_session_horizon_session_user: 'internal.horizon_experiencescore_session.horizon_session_user',
    internal_horizon_experiencescore_session_horizon_generation: 'internal.horizon_experiencescore_session.horizon_generation',
    internal_horizon_experiencescore_session_pod_name: 'internal.horizon_experiencescore_session.pod_name',
    internal_horizon_experiencescore_session_pool: 'internal.horizon_experiencescore_session.pool',
    internal_horizon_experiencescore_session_pooltype_v2: 'internal.horizon_experiencescore_session.pooltype_v2',
    internal_horizon_experiencescore_session_session_uuid: 'internal.horizon_experiencescore_session.session_uuid',
    internal_horizon_experiencescore_session_score_calculation_timestamp:
      'internal.horizon_experiencescore_session.score_calculation_timestamp',
    internal_horizon_experiencescore_session_vm_id: 'internal.horizon_experiencescore_session.vm_id',

    // prettier-ignore
    // eslint-disable-next-line max-len
    internal_experiencescore_desktop_app_org_total_enrolled_device_count: 'internal.experiencescore_desktop_app.org_total_enrolled_device_count',
    internal_experiencescore_desktop_app_user_overall_score: 'internal.experiencescore_desktop_app._user_overall_score',
    internal_experiencescore_desktop_app_user_top_desktop_apps: 'internal.experiencescore_desktop_app.user_top_desktop_apps',
    // experiencescore_device
    internal_experiencescore_device_airwatch_device_guid: 'internal.experiencescore_device._airwatch_device_guid',
    internal_experiencescore_device_adp_modified_at: 'internal.experiencescore_device.adp_modified_at',
    internal_experiencescore_device_average_boot_time_millis: 'internal.experiencescore_device.average_boot_time_millis',
    internal_experiencescore_device_average_boot_time_millis_score: 'internal.experiencescore_device._average_boot_time_millis_score',
    internal_experiencescore_device_average_shutdown_time_millis: 'internal.experiencescore_device.average_shutdown_time_millis',
    // prettier-ignore
    // eslint-disable-next-line max-len
    internal_experiencescore_device_average_shutdown_time_millis_score: 'internal.experiencescore_device._average_shutdown_time_millis_score',
    internal_experiencescore_device_battery_health: 'internal.experiencescore_device.battery_health',
    internal_experiencescore_device_battery_health_score: 'internal.experiencescore_device._battery_health_score',
    internal_experiencescore_device_cpu_health: 'internal.experiencescore_device.cpu_health',
    internal_experiencescore_device_cpu_health_score: 'internal.experiencescore_device._cpu_health_score',
    internal_experiencescore_device_device_crash_count_score: 'internal.experiencescore_device._device_crash_count_score',
    internal_experiencescore_device_device_crash_rate_score: 'internal.experiencescore_device._device_crash_rate_score',
    internal_experiencescore_device_crashes: 'internal.experiencescore_device.crashes',
    internal_experiencescore_device_created_at: 'internal.experiencescore_device.created_at',
    internal_experiencescore_device_device_guid: 'internal.experiencescore_device.device_guid',
    internal_experiencescore_device_device_location_group_name: 'internal.experiencescore_device.device_location_group_name',
    internal_experiencescore_device_device_model: 'internal.experiencescore_device.device_model',
    internal_experiencescore_device_device_name: 'internal.experiencescore_device.device_name',
    internal_experiencescore_device_device_overall_score: 'internal.experiencescore_device._device_overall_score',
    internal_experiencescore_device_device_platform: 'internal.experiencescore_device._device_platform',
    internal_experiencescore_device_device_temperature_score: 'internal.experiencescore_device._device_temperature_score',
    internal_experiencescore_device_enrollment_user_name: 'internal.experiencescore_device.device_enrollment_user_name',
    internal_experiencescore_device_gpu_health: 'internal.experiencescore_device.gpu_health',
    internal_experiencescore_device_gpu_health_score: 'internal.experiencescore_device._gpu_health_score',
    internal_experiencescore_device_integration: 'internal.experiencescore_device.integration',
    internal_experiencescore_device_issue: 'internal.experiencescore_device.issue',
    internal_experiencescore_device_memory_health: 'internal.experiencescore_device.memory_health',
    internal_experiencescore_device_memory_health_score: 'internal.experiencescore_device._memory_health_score',
    internal_experiencescore_device_wifi_strength: 'internal.experiencescore_device.wifi_strength_percentage',
    internal_experiencescore_device_wifi_strength_score: 'internal.experiencescore_device._wifi_strength_score',
    internal_experiencescore_device_download_speed: 'internal.experiencescore_device.download_speed_in_mbps',
    internal_experiencescore_device_download_speed_score: 'internal.experiencescore_device._download_speed_score',
    internal_experiencescore_device_upload_speed: 'internal.experiencescore_device.upload_speed_in_mbps',
    internal_experiencescore_device_upload_speed_score: 'internal.experiencescore_device._upload_speed_score',
    internal_experiencescore_device_os_version: 'internal.experiencescore_device.os_version',
    internal_experiencescore_device_os_version_friendly_name: 'internal.experiencescore_device._device_os_version_friendly_name',
    internal_experiencescore_device_platform: 'internal.experiencescore_device._device_platform',
    internal_experiencescore_device_user_guid: 'internal.experiencescore_device.user_guid',
    internal_experiencescore_device_user_overall_score: 'internal.experiencescore_device._user_overall_score',
    internal_experiencescore_device_score_calculation_timestamp: 'internal.experiencescore_device.score_calculation_timestamp',

    // experiencescore_mobile_app
    internal_experiencescore_mobile_app_adp_modified_at: 'internal.experiencescore_mobile_app.adp_modified_at',
    internal_experiencescore_mobile_app_app_crash_rate: 'internal.experiencescore_mobile_app.app_crash_rate',
    internal_experiencescore_mobile_app_app_crash_count: 'internal.experiencescore_mobile_app.app_crash_count',
    internal_experiencescore_mobile_app_app_config_app_name: 'internal.experiencescore_mobile_app.app_config_app_name',
    internal_experiencescore_mobile_app_app_handled_exceptions: 'internal.experiencescore_mobile_app.app_handled_exceptions',
    internal_experiencescore_mobile_app_app_id: 'internal.experiencescore_mobile_app.app_id',
    internal_experiencescore_mobile_app_app_id_version: 'internal.experiencescore_mobile_app.app_id_version',
    internal_experiencescore_mobile_app_app_net_error_rate: 'internal.experiencescore_mobile_app.app_net_error_rate',
    internal_experiencescore_mobile_app_app_package_id: 'internal.experiencescore_mobile_app.app_package_id',
    internal_experiencescore_mobile_app_app_version: 'internal.experiencescore_mobile_app.app_version',
    internal_experiencescore_mobile_app__app_version: 'internal.experiencescore_mobile_app._app_version',
    internal_experiencescore_mobile_app_avg_app_latency_millis: 'internal.experiencescore_mobile_app.avg_app_latency_millis',
    internal_experiencescore_mobile_app_created_at: 'internal.experiencescore_mobile_app.created_at',
    internal_experiencescore_mobile_app_device_platform: 'internal.experiencescore_mobile_app._device_platform',
    internal_experiencescore_mobile_app_entity: 'internal.experiencescore_mobile_app.entity',
    internal_experiencescore_mobile_app_impact: 'internal.experiencescore_mobile_app.impact',
    internal_experiencescore_mobile_app_integration: 'internal.experiencescore_mobile_app._integration',
    internal_experiencescore_mobile_app_app_type: 'internal.experiencescore_mobile_app.app_type',
    // prettier-ignore
    // eslint-disable-next-line max-len
    internal_experiencescore_mobile_app_mobile_app_handled_exceptions_score: 'internal.experiencescore_mobile_app._mobile_app_handled_exceptions_score',
    // prettier-ignore
    // eslint-disable-next-line max-len
    internal_experiencescore_mobile_app_mobile_app_avg_app_latency_millis_score: 'internal.experiencescore_mobile_app._mobile_app_avg_app_latency_millis_score',
    internal_experiencescore_mobile_app_mobile_app_crash_rate_score: 'internal.experiencescore_mobile_app._mobile_app_crash_rate_score',
    // prettier-ignore
    // eslint-disable-next-line max-len
    internal_experiencescore_mobile_app_mobile_app_net_error_rate_score: 'internal.experiencescore_mobile_app._mobile_app_net_error_rate_score',
    internal_experiencescore_mobile_app_mobile_app_overall_score: 'internal.experiencescore_mobile_app._mobile_app_overall_score',
    internal_experiencescore_mobile_app_productivity_app: 'internal.experiencescore_mobile_app.productivity_app',
    internal_experiencescore_mobile_app_user_overall_score: 'internal.experiencescore_mobile_app._user_overall_score',
    internal_experiencescore_mobile_app_user_productivity_apps: 'internal.experiencescore_mobile_app.user_productivity_apps',
    internal_experiencescore_mobile_app_score_calculation_timestamp: 'internal.experiencescore_mobile_app.score_calculation_timestamp',
    internal_experiencescore_mobile_app_app_level_data: 'internal.experiencescore_mobile_app.app_level_data',

    // experiencescore_user
    internal_experiencescore_user_adp_modified_at: 'internal.experiencescore_user.adp_modified_at',
    internal_experiencescore_user_apps_with_good_score_count: 'internal.experiencescore_user.apps_with_good_score_count',
    internal_experiencescore_user_apps_with_good_score_percent: 'internal.experiencescore_user.apps_with_good_score_percent',
    internal_experiencescore_user_score_calculation_timestamp: 'internal.experiencescore_user.score_calculation_timestamp',
    internal_experiencescore_user_user_desktop_app_health_score: 'internal.experiencescore_user._user_desktop_app_health_score',
    internal_experiencescore_user_user_device_health_score: 'internal.experiencescore_user._user_device_health_score',
    internal_experiencescore_user_user_guid: 'internal.experiencescore_user.user_guid',
    internal_experiencescore_user_user_overall_score: 'internal.experiencescore_user._user_overall_score',
    internal_experiencescore_user_user_productivity_apps: 'internal.experiencescore_user.user_productivity_apps',
    internal_experiencescore_user_user_top_desktop_apps: 'internal.experiencescore_user.user_top_desktop_apps',
    internal_experiencescore_user_username: 'internal.experiencescore_user.username',
    // experiencescore_org
    internal_experiencescore_org_event_timestamp: 'internal.experiencescore_org.event_timestamp',
    internal_experiencescore_org_org_overall_score: 'internal.experiencescore_org._org_overall_score',
    // horizon_experiencescore_org
    internal_horizon_experiencescore_org_event_timestamp: 'internal.horizon_experiencescore_org.event_timestamp',
    internal_horizon_experiencescore_org_org_overall_score: 'internal.horizon_experiencescore_org.org_overall_score',

    // product_catalog
    internal_product_catalog_categories: 'internal.product_catalog.categories',
    internal_product_catalog_product_name: 'internal.product_catalog.product_name',
    internal_product_catalog_publisher: 'internal.product_catalog.publisher',
    internal_product_catalog_url: 'internal.product_catalog.url',
    // product_catalog_metrics
    internal_product_catalog_metrics_product_id: 'internal.product_catalog_metrics.product_id',
    internal_product_catalog_metrics_product_name: 'internal.product_catalog_metrics.product_name',
    internal_product_catalog_metrics_foreground_activity_lookback_interval:
      'internal.product_catalog_metrics.foreground_activity_lookback_interval',
    internal_product_catalog_metrics_device_platform_list: 'internal.product_catalog_metrics.device_platform_list',
    internal_product_catalog_metrics_managed_status_list: 'internal.product_catalog_metrics.managed_status_list',
    internal_product_catalog_metrics_is_product_total: 'internal.product_catalog_metrics.is_product_total',
    internal_product_catalog_metrics_avg_foreground_time: 'internal.product_catalog_metrics.avg_foreground_time',
    internal_product_catalog_metrics_total_foreground_time: 'internal.product_catalog_metrics.total_foreground_time',
    internal_product_catalog_metrics_active_product_device_count: 'internal.product_catalog_metrics.active_product_device_count',
    internal_product_catalog_metrics_inactive_product_device_count: 'internal.product_catalog_metrics.inactive_product_device_count',
    internal_product_catalog_metrics_total_product_device_count: 'internal.product_catalog_metrics.total_product_device_count',
    internal_product_catalog_metrics_active_product_device_percentage: 'internal.product_catalog_metrics.active_product_device_percentage',
    internal_product_catalog_metrics_inactive_product_device_percentage:
      'internal.product_catalog_metrics.inactive_product_device_percentage',
    internal_product_catalog_metrics_product_create_date: 'internal.product_catalog_metrics.product_create_date',

    // synthetic_url_monitoring
    employee_experience_synthetic_url_monitoring_airwatch_device_guid: 'employee_experience.synthetic_url_monitoring._airwatch_device_guid',
    employee_experience_synthetic_url_monitoring_device_platform: 'employee_experience.synthetic_url_monitoring._device_platform',
    employee_experience_synthetic_url_monitoring_adp_modified_at: 'employee_experience.synthetic_url_monitoring.adp_modified_at',
    employee_experience_synthetic_url_monitoring_cert_valid_from_time: 'employee_experience.synthetic_url_monitoring.cert_valid_from_time',
    employee_experience_synthetic_url_monitoring_cert_valid_to_time: 'employee_experience.synthetic_url_monitoring.cert_valid_to_time',
    employee_experience_synthetic_url_monitoring_device_model: 'employee_experience.synthetic_url_monitoring.device_model',
    employee_experience_synthetic_url_monitoring_device_name: 'employee_experience.synthetic_url_monitoring.device_name',
    employee_experience_synthetic_url_monitoring_fail_reason: 'employee_experience.synthetic_url_monitoring.fail_reason',
    employee_experience_synthetic_url_monitoring_http_resp_millis: 'employee_experience.synthetic_url_monitoring.http_resp_millis',
    employee_experience_synthetic_url_monitoring_http_status_code: 'employee_experience.synthetic_url_monitoring.http_status_code',
    employee_experience_synthetic_url_monitoring_is_success: 'employee_experience.synthetic_url_monitoring.is_success',
    employee_experience_synthetic_url_monitoring_name_res_millis: 'employee_experience.synthetic_url_monitoring.name_res_millis',
    employee_experience_synthetic_url_monitoring_socket_conn_millis: 'employee_experience.synthetic_url_monitoring.socket_conn_millis',
    employee_experience_synthetic_url_monitoring_target_address: 'employee_experience.synthetic_url_monitoring.target_address',
    employee_experience_synthetic_url_monitoring_target_ip_address: 'employee_experience.synthetic_url_monitoring.target_ip_address',
    employee_experience_synthetic_url_monitoring_test_id: 'employee_experience.synthetic_url_monitoring.test_id',
    employee_experience_synthetic_url_monitoring_test_type: 'employee_experience.synthetic_url_monitoring.test_type',
    employee_experience_synthetic_url_monitoring_tls_setup_millis: 'employee_experience.synthetic_url_monitoring.tls_setup_millis',
    employee_experience_synthetic_url_monitoring_total_resp_millis: 'employee_experience.synthetic_url_monitoring.total_resp_millis',

    // insights
    internal_auditlog_audit_event_type: 'internal.auditlog.audit_event_type',
    internal_auditlog_event_timestamp: 'internal.auditlog.event_timestamp',
    internal_auditlog_object_id: 'internal.auditlog.object_id',
    internal_auditlog_object_name: 'internal.auditlog.object_name',
    internal_auditlog_object_type: 'internal.auditlog.object_type',
    internal_auditlog_source_object_type: 'internal.auditlog.source_object_type',
    internal_auditlog_search_fields_source_object_id: 'internal.auditlog.search_fields->source_object_id',
    internal_auditlog_search_fields_source_object_type: 'internal.auditlog.search_fields->source_object_type',
    internal_insights_adp_modified_at: 'internal.insights.adp_modified_at',
    internal_insights_event_timestamp: 'internal.insights.event_timestamp',
    internal_insights_insight_type: 'internal.insights.insight_type',
    internal_insights_last_triggered_at: 'internal.insights.last_triggered_at',
    internal_insights_lower_bound: 'internal.insights.lower_bound',
    internal_insights_observation_name: 'internal.insights.observation_name',
    internal_insights_observation_value_long: 'internal.insights.observation_value_long',
    internal_insights_outlier_type: 'internal.insights.outlier_type',
    internal_insights_platform: 'internal.insights.platform',
    internal_insights_upper_bound: 'internal.insights.upper_bound',
    internal_insights_version: 'internal.insights.version',
    // RCA
    internal_rca_sample_device_guid: 'internal.rca_sample.device_guid',
    internal_rca_sample_device_name: 'internal.rca_sample.device_name',
    internal_rca_sample_glue_job_id: 'internal.rca_sample.glue_job_id',
    internal_rca_sample_rca_result_id: 'internal.rca_sample.rca_result_id',
    internal_rca_sample_sample_timestamp: 'internal.rca_sample.sample_timestamp',
    // vulnerability
    internal_ios_vulnerability_cve_id: 'internal.ios_vulnerability.cve_id',
    internal_ios_vulnerability_published_date: 'internal.ios_vulnerability.published_date',
    internal_macos_vulnerability_published_date: 'internal.macos_vulnerability.published_date',
    internal_vulnerability_summary_cve_id: 'internal.vulnerability_summary.cve_id',
    internal_vulnerability_summary_cvss_score: 'internal.vulnerability_summary.nvd_cvssv2_basescore',
    internal_vulnerability_summary_cvss_score_v3: 'internal.vulnerability_summary.nvd_cvssv3_basescore',
    internal_vulnerability_summary_org_id: 'internal.vulnerability_summary._org_id',
    internal_windows_vulnerability_cve_id: 'internal.windows_vulnerability.cve_id',
    internal_windows_vulnerability_published_date: 'internal.windows_vulnerability.published_date',

    internal_workflowexecution_action: 'internal.workflowexecution.action',
    internal_workflowexecution_action_id: 'internal.workflowexecution.action_id',
    internal_workflowexecution_action_name: 'internal.workflowexecution.action_name',
    internal_workflowexecution_automation_event_type: 'internal.workflowexecution.automation_event_type',
    internal_workflowexecution_created_at: 'internal.workflowexecution.created_at',
    internal_workflowexecution_details: 'internal.workflowexecution.details',
    internal_workflowexecution_end_time: 'internal.workflowexecution.end_time',
    internal_workflowexecution_rule_id: 'internal.workflowexecution.rule_id',
    internal_workflowexecution_rule_target_keys: 'internal.workflowexecution.rule_target_keys',
    internal_workflowexecution_service_name: 'internal.workflowexecution.service_name',
    internal_workflowexecution_service_type: 'internal.workflowexecution.service_type',
    internal_workflowexecution_status: 'internal.workflowexecution.status',
    internal_workflowexecution_start_time: 'internal.workflowexecution.start_time',
    internal_workflowexecution_target_id: 'internal.workflowexecution.target_id',
    internal_workflowexecution_target_count: 'internal.workflowexecution.target_count',
    internal_workflowexecution_target_type: 'internal.workflowexecution.target_type',
    internal_workflowexecution_workflow_execution_context_id: 'internal.workflowexecution.workflow_execution_context_id',
    internal_workflowexecution_workflow_execution_group_name: 'internal.workflowexecution.group_name',
    internal_workflowexecution_workflow_execution_switch_name: 'internal.workflowexecution.switch_name',
    internal_workflowexecution_workflow_execution_switch_branch_name: 'internal.workflowexecution.switch_branch_name',
    lookout_threat_event_id: 'lookout.threat.event_id',
    lookout_threat_event_timestamp: 'lookout.threat.event_timestamp',
    lookout_threat_partner_name: 'lookout.threat.partner_name',
    lookout_threat_threat_family: 'lookout.threat._threat_family',
    lookout_threat_threat_severity: 'lookout.threat._threat_severity',
    lookout_threat_vmware_threat_type: 'lookout.threat._vmware_threat_type',
    netskope_threat_event_id: 'netskope.threat.event_id',
    netskope_threat_event_timestamp: 'netskope.threat.event_timestamp',
    netskope_threat_partner_name: 'netskope.threat.partner_name',
    netskope_threat_threat_family: 'netskope.threat._threat_family',
    netskope_threat_threat_severity: 'netskope.threat._threat_severity',
    netskope_threat_vmware_threat_type: 'netskope.threat._vmware_threat_type',
    pradeo_threat_event_timestamp: 'pradeo.threat.event_timestamp',
    pradeo_threat_partner_name: 'pradeo.threat.partner_name',
    pradeo_threat_threat_family: 'pradeo.threat._threat_family',
    pradeo_threat_threat_severity: 'pradeo.threat._threat_severity',
    pradeo_threat_vmware_threat_type: 'pradeo.threat._vmware_threat_type',
    uag_tunnel_app_bundle_id: 'uag.tunnel.app_bundle_id',
    uag_tunnel_dest_ip: 'uag.tunnel.dest_ip',
    uag_tunnel_dest_port: 'uag.tunnel.dest_port',
    uag_tunnel_device_name: 'uag.tunnel.device_name',
    uag_tunnel_down_bytes: 'uag.tunnel.down_bytes',
    uag_tunnel_host_name: 'uag.tunnel.host_name',
    uag_tunnel_protocol: 'uag.tunnel.protocol',
    uag_tunnel_uag_name: 'uag.tunnel.uag_name',
    uag_tunnel_up_bytes: 'uag.tunnel.up_bytes',
    uag_tunnel_user_name: 'uag.tunnel.user_name',
    wandera_threat_event_timestamp: 'wandera.threat.event_timestamp',
    wandera_threat_partner_name: 'wandera.threat.partner_name',
    wandera_threat_threat_family: 'wandera.threat._threat_family',
    wandera_threat_threat_severity: 'wandera.threat._threat_severity',
    wandera_threat_vmware_threat_type: 'wandera.threat._vmware_threat_type',
    zscaler_threat_event_timestamp: 'zscaler.threat.event_timestamp',
    zimperium_threat_event_timestamp: 'zimperium.threat.event_timestamp',
    zimperium_threat_partner_name: 'zimperium.threat.partner_name',
    zimperium_threat_threat_family: 'zimperium.threat._threat_family',
    zimperium_threat_threat_severity: 'zimperium.threat._threat_severity',
    zimperium_threat_vmware_threat_type: 'zimperium.threat._vmware_threat_type',

    // Zoom
    zoom_qos__airwatch_device__device_friendly_name: 'zoom.quality_of_service._airwatch_device__device_friendly_name',
    zoom_qos__airwatch_device_guid: 'zoom.quality_of_service._airwatch_device_guid',
    zoom_qos__customer_device_id: 'zoom.quality_of_service._customer_device_id',
    zoom_qos__device_platform: 'zoom.quality_of_service._device_platform',
    zoom_qos__meeting_end_time: 'zoom.quality_of_service._meeting_end_time',
    zoom_qos__meeting_start_time: 'zoom.quality_of_service._meeting_start_time',
    zoom_qos__qos_type: 'zoom.quality_of_service._qos_type',
    zoom_qos_event_timestamp: 'zoom.quality_of_service.event_timestamp',
    zoom_qos_payload_object_participant_data_camera: 'zoom.quality_of_service.payload_object_participant_data_camera',
    zoom_qos_payload_object_participant_data_microphone: 'zoom.quality_of_service.payload_object_participant_data_microphone',
    zoom_qos_payload_object_participant_data_speaker: 'zoom.quality_of_service.payload_object_participant_data_speaker',
    zoom_qos_payload_object_duration: 'zoom.quality_of_service.payload_object_duration',
    zoom_qos_payload_object_participant_qos_details_quality: 'zoom.quality_of_service.payload_object_participant_qos_details_quality',
    // prettier-ignore
    // eslint-disable-next-line max-len
    zoom_qos_payload_object_participant_qos_details_quality_score: 'zoom.quality_of_service.payload_object_participant_qos_details_quality_score',
    zoom_qos_payload_object_participant_id: 'zoom.quality_of_service.payload_object_participant_id',
    zoom_qos_payload_account_id: 'zoom.quality_of_service.payload_account_id',
    zoom_qos_payload_object_end_time: 'zoom.quality_of_service.payload_object_end_time',
    zoom_qos_payload_object_participant_data_version: 'zoom.quality_of_service.payload_object_participant_data_version',
    zoom_qos_payload_object_uuid: 'zoom.quality_of_service.payload_object_uuid',
    zoom_qos_payload_object_start_time: 'zoom.quality_of_service.payload_object_start_time',
    zoom_qos_payload_object_participant_qos_type: 'zoom.quality_of_service.payload_object_participant_qos_type',
    zoom_qos_payload_object_participant_participant_uuid: 'zoom.quality_of_service.payload_object_participant_participant_uuid',
    zoom_qos_payload_object_participant_user_name: 'zoom.quality_of_service.payload_object_participant_user_name',
  },
};

export const ORG_GROUP_ATTRIBUTES_MAPPING = {
  [COLUMN_NAMES.byName.device_location_group_name]: COLUMN_NAMES.byName._airwatch_device__device_location_group_name,
  [COLUMN_NAMES.byName.device_location_group_lineage_list]: COLUMN_NAMES.byName._airwatch_device__device_location_group_lineage_list,
};
