/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { Serializable } from '@dpa/ui-common';

import { FilterRule } from './filter-rule.model';
import { RuleGroup } from './rule-group.model';

/**
 * CustomAttributeFilterRule
 * @export
 * @class CustomAttributeFilterRule
 */
@Serializable
export class CustomAttributeFilterRule extends FilterRule {
  /*
    customAttributeRuleGroup: Rule Group for custom attributes like sensors whose data_type
    will be STRUCTMAP (There will be no further nesting under customAttributeRuleGroup -> rules)
  */
  public customAttributeRuleGroup: RuleGroup;
  /*
    customAttributeIdentifier: Each filter under customAttributeRuleGroup will contain Identifier denoting
    key of key-value pair of custom attributes, This hold first column value of 4 columns in filters
  */
  public customAttributeIdentifier: string;

  /**
   * constructor
   * @param {Array<Partial<CustomAttributeFilterRule>>} args
   * @memberof CustomAttributeFilterRule
   */
  constructor(...args: Array<Partial<CustomAttributeFilterRule>>) {
    super();
    Object.assign(this, ...args);
  }

  /**
   * hasRuleValue
   * @override
   * @param {any} allColumnsByName
   * @returns {boolean}
   * @memberof CustomAttributeFilterRule
   */
  public hasRuleValue(allColumnsByName: any): boolean {
    let hasRuleValue = false;
    if (this.isCustomAttributeFilterRule()) {
      hasRuleValue = this.isCustomAttributeFilterRuleValid(allColumnsByName);
    } else {
      hasRuleValue = !this.valueRequired || this.doesValueExist(this.data);
    }
    return hasRuleValue;
  }

  /**
   * isCustomAttributeFilterRuleValid
   * @param {any} allColumnsByName
   * @returns {boolean}
   * @memberof CustomAttributeFilterRule
   */
  public isCustomAttributeFilterRuleValid(allColumnsByName: any): boolean {
    if (!this.customAttributeRuleGroup?.rules?.length) {
      return false;
    }
    // Returning false if any of the inner rule is invalid
    return !this.customAttributeRuleGroup?.rules?.some((rule: CustomAttributeFilterRule) => {
      return !rule.isValid(allColumnsByName);
    });
  }
}
